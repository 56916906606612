import React from 'react'
import styled from 'styled-components'
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    RedditShareButton,
    LinkedinShareButton,
    FacebookShareCount,
    RedditShareCount,
    PinterestShareCount,
} from "react-share";
import CountUp from 'react-countup';


export const ShareBarColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
`

export const ShareBarColumnLabel = styled.label`
    padding-top: 0px;
    padding-bottom: 11px;
    font-family: Roboto;
    font-size: 13px;
    color: rgb(34, 34, 32);
    font-weight: bold;
`



const Container = styled.div`
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, 32px);
            grid-gap: 10px;
            min-height: 32px;

          

            .share-item{
                display: flex;
                flex-direction: column;
                height: 60px;
                justify-content: space-between;
            }

            .facebook-item{
                display: flex;
                background: #4267b2;
                justify-content: center;
                align-items: center;
                height: 32px;
                width: 32px;
                min-height: 32px;
                max-height: 32px;
                max-width: 32px;
                min-width: 32px;
                border-radius: 4px;

            }
            
            .reddit-item{
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgb(255, 69, 0);
                height: 32px;
                width: 32px;
                min-height: 32px;
                max-height: 32px;
                max-width: 32px;
                min-width: 32px;
                border-radius: 4px;

            }

            .pinterest-item{
                display: flex;
                justify-content: center;
                align-items: center;
                background: #e60023;
                height: 32px;
                width: 32px;
                min-height: 32px;
                max-height: 32px;
                max-width: 32px;
                min-width: 32px;
                border-radius: 4px;

            }

            .linkedin-item{
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgb(0, 115, 177);
                height: 32px;
                width: 32px;
                height: 32px;
                width: 32px;
                min-height: 32px;
                max-height: 32px;
                max-width: 32px;
                min-width: 32px;
                border-radius: 4px;

            }
            
            .twitter-item{
                display: flex;
                justify-content: center;
                align-items: center;

                background: rgb(29, 161, 242);
                height: 32px;
                width: 32px;
                height: 32px;
                width: 32px;
                min-height: 32px;
                max-height: 32px;
                max-width: 32px;
                min-width: 32px;
                border-radius: 4px;

            }

            .share-button{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 32px;
                border-radius: 4px;

                svg{
                    path{
                        fill: white;
                    }
                }
            }

          

            .myShareCountWrapper{
                display: flex;
                color: black;
                align-items: center;
                justify-content: center;
                min-height: 20px;
                max-height: 20px;

                border-radius: 4px;
                font-size: 9px;
                line-height: 20px;
                width: 20px;
                color: rgba(0, 0, 0, 0.5);
                font-family: var(--Font2);
                border: 1px solid rgba(0, 0, 0, 0.5);
                
            }
        
    

        .share-count{
            display: flex;
            justify-content: center;
            width: 32px;
            height: 20px;
        }
`



export const TwitterIcon = () => {
    return (
        <svg aria-hidden="true" style={{ width: 19, height: 19, maxHeight: 19, maxWidth: 19 }} focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg>
    )
}

export const FacebookIcon = () => {
    return (
        <svg ariahidden="true" style={{ width: 19, height: 19, maxHeight: 19, maxWidth: 19 }} focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-inline--fa fa-facebook-f fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>

    )
}

export const RedditIcon = () => {
    return (
        <svg aria-hidden="true" style={{ width: 19, height: 19, maxHeight: 19, maxWidth: 19 }} focusable="false" data-prefix="fab" data-icon="reddit-alien" className="svg-inline--fa fa-reddit-alien fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M440.3 203.5c-15 0-28.2 6.2-37.9 15.9-35.7-24.7-83.8-40.6-137.1-42.3L293 52.3l88.2 19.8c0 21.6 17.6 39.2 39.2 39.2 22 0 39.7-18.1 39.7-39.7s-17.6-39.7-39.7-39.7c-15.4 0-28.7 9.3-35.3 22l-97.4-21.6c-4.9-1.3-9.7 2.2-11 7.1L246.3 177c-52.9 2.2-100.5 18.1-136.3 42.8-9.7-10.1-23.4-16.3-38.4-16.3-55.6 0-73.8 74.6-22.9 100.1-1.8 7.9-2.6 16.3-2.6 24.7 0 83.8 94.4 151.7 210.3 151.7 116.4 0 210.8-67.9 210.8-151.7 0-8.4-.9-17.2-3.1-25.1 49.9-25.6 31.5-99.7-23.8-99.7zM129.4 308.9c0-22 17.6-39.7 39.7-39.7 21.6 0 39.2 17.6 39.2 39.7 0 21.6-17.6 39.2-39.2 39.2-22 .1-39.7-17.6-39.7-39.2zm214.3 93.5c-36.4 36.4-139.1 36.4-175.5 0-4-3.5-4-9.7 0-13.7 3.5-3.5 9.7-3.5 13.2 0 27.8 28.5 120 29 149 0 3.5-3.5 9.7-3.5 13.2 0 4.1 4 4.1 10.2.1 13.7zm-.8-54.2c-21.6 0-39.2-17.6-39.2-39.2 0-22 17.6-39.7 39.2-39.7 22 0 39.7 17.6 39.7 39.7-.1 21.5-17.7 39.2-39.7 39.2z" /></svg>
    )
}

export const LinkedInIcon = () => {
    return (
        <svg aria-hidden="true" style={{ width: 19, height: 19, maxHeight: 19, maxWidth: 19 }} focusable="false" data-prefix="fab" data-icon="linkedin-in" className="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>
    )
}

export const PinterestIcon = () => {
    return (
        <svg aria-hidden="true" style={{ width: 19, height: 19, maxHeight: 19, maxWidth: 19 }} focusable="false" data-prefix="fab" data-icon="pinterest-p" className="svg-inline--fa fa-pinterest-p fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" /></svg>
    )
}


const ShareBar = ({title, hashtags, media}) => {
    const shareUrl = typeof window !== 'undefined' ? `${window.location.origin}${window.location.pathname}` : '';
    return (
        <>
            {shareUrl ?
                <Container>
                    <div className="share-item">
                        <FacebookShareButton quote={title} className="share-button" hashtag={"#findanherb #madeinthe801"} size={32} url={shareUrl} >
                            <div className="facebook-item">
                                <FacebookIcon />
                            </div>
                        </FacebookShareButton>
                        <FacebookShareCount className="share-count" url={shareUrl}>
                            {shareCount => <span className="myShareCountWrapper"> {shareCount ? <CountUp duration={2.75} end={shareCount} /> : 0}</span>}
                        </FacebookShareCount>
                    </div>
                    <div className="share-item">
                        <TwitterShareButton hashtags={[...hashtags]} className="share-button" title={title} size={32} url={shareUrl}>
                            <div className="twitter-item">
                                <TwitterIcon />
                            </div>
                        </TwitterShareButton>
                    </div>
                    <div className="share-item">

                        <RedditShareButton className="share-button" title={title} size={32} url={shareUrl}>
                            <div className="reddit-item">
                                <RedditIcon />
                            </div>
                        </RedditShareButton>
                        <RedditShareCount className="share-count" url={shareUrl}>
                            {shareCount => <span className="myShareCountWrapper">{shareCount ? <CountUp end={shareCount} /> : 0}</span>}
                        </RedditShareCount>

                    </div>
                    <div className="share-item">
                        <PinterestShareButton className="share-button" media={media} size={32} url={shareUrl} >
                            <div className="pinterest-item">
                                <PinterestIcon />

                            </div>
                        </PinterestShareButton>
                        <PinterestShareCount className="share-count" url={shareUrl}>
                            {shareCount => <span className="myShareCountWrapper">
                                {shareCount ? <CountUp duration={2.75} end={shareCount} /> : 0}
                            </span>}

                        </PinterestShareCount>
                    </div>
                    <div className="share-item">
                        <LinkedinShareButton className="share-button" size={32} url={shareUrl} >
                            <div className="linkedin-item">
                                <LinkedInIcon />
                            </div>
                        </LinkedinShareButton>

                    </div>
                </Container> : ""}
        </>
    )

}

ShareBar.defaultProps = {
    title: "title",
    hashtags: ["findanherb", "herbfacts"],
    media: ""
}



export default ShareBar;