import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { BreakpointTablet, SectionLabelOne } from '../Layout/GlobalStyle';
import { getProductsByHerbID, getProductsByAilmentID } from '../../services/product.services';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import LoadingSpinner2 from '../common/LoadingContainer/LoadingSpinner2';
import ProductItem from './ProductItem';
import PropTypes from 'prop-types'
import ProductsSection from '.';




const Container = styled.div`
    /* border-bottom: 1px solid #ccc; */
    /* min-height: 316px; */
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;

    /* .products-section-title{
        font-family: var(--Font1);
        text-transform: uppercase;
        font-weight: bold;
        color: #222220;
        padding-bottom: 25px;
    } */

    .product-grid{
        display: grid;
        grid-template-columns: repeat(auto-fill, 130px);
        grid-gap: 25px;
        width: 100%;
    }

    @media screen and (max-width: ${BreakpointTablet + 'px'}) {
        padding-top: 0px;
        .products-section-title{
            font-size: 16px;
            padding-bottom: 15px;
        }

        .product-grid{
            grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
        }
    }

`

// const ProductItem = styled.div`
//     display: flex;
//     flex-direction: column;
//     .product-image{
//         background: #D5D5D5;
//         width: 100%;
//         height: 130px;
//         background-size: cover;
//         position: relative;
//         background-position: 50%;
//         ${props => props.src ? `background-image: url(${props.src});` : ''};
//     }

//     .product-title{
//         font-size: 13px;
//         color: #222220;
//         font-family: var(--Font1);
//         line-height: 13px;

//         :hover{
//             text-decoration: underline;
//         }
//     }

//     .product-information{
//         height: 30px;
//         display: flex;
//         align-items: center;
//     }

//     @media screen and (max-width: ${BreakpointTablet + 'px'}) {
//         .product-image{
//             height: 102px;   
//         }

        

//         background: #F7F7F7;
//         padding: 6px;
//     }

//     :hover{
//         .product-title{
//             color: var(--Color2);
//         }
        
//     }

// `

const LoadMore = styled.button`
    height: 34px;
    width: 100%;
    margin-top: 14px;
    font-size: 16px;
    font-family: var(--Font1);
    background: var(--Color2);
    color: white;
    text-transform: uppercase;
    border: 0px solid transparent;
`

const defaultLimit = 5;

const ProductsSectionGrid = ({ailment, herb, forHerb, forAilment}) => {
    const [products, setProducts] = useState([])
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(defaultLimit)


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            loadData()
           
        }
        return () => isSubscribed = false;
    }, [herb, ailment])


    const loadData = () => {
        if(forAilment){
            loadByAilment()
        }

        if(forHerb){
            loadByHerb()
        }
    }


 

    const loadByAilment = () => {
            setLoading(true)
            if(ailment._id){

            getProductsByAilmentID(ailment._id, `limit?=${limit}`)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false)
                        setProducts(res.data.products)
                        setCount(res.data.count)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    throw err
                })
            } else if(ailment.mongodb_id){

            getProductsByAilmentID(ailment.mongodb_id, `limit?=${limit}`)
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setProducts(res.data.products)
                    setCount(res.data.count)
                }
            })
            .catch(err => {
                setLoading(false)
                throw err
            })
        }
    }

    const loadByHerb = () => {
        setLoading(true)

        if (herb.mongodb_id) {
            getProductsByHerbID(herb.mongodb_id, `?limit=${limit}`)
                .then(res => {
                    if (res.status === 200) {
                        setProducts(res.data.products)
                        setCount(res.data.count)
                        setLoading(false)

                    }
                })
                .catch(err => {
                    throw err
                })
        } else if (herb._id) {
            getProductsByHerbID(herb._id, `?limit=${limit}`)
                .then(res => {
                    if (res.status === 200) {
                        setProducts(res.data.products)
                        setCount(res.data.count)
                        setLoading(false)

                    }
                })
                .catch(err => {
                    throw err
                })
        }
    }





    let renderProducts = products.map((p, i) => {
        return (
            
            <ProductItem item={p} key={i}  />
        )
    })

const herbTitle = loading && herb.name !== "" ? <span className="loading-place-holder-text">
Looking for products with {herb.name}
</span> : loading && !herb.name ? `Looking for products` : !loading && products.length === 0 ? <span className="not-found-text">No Products Found For {herb.name}</span> : "Products with " + herb.name;

const ailmentTitle = loading && ailment.name !== "" ? <span className="loading-place-holder-text">
Looking for products that may help {ailment.name}
</span> : loading && !ailment.name ? `Looking for products` : !loading && products.length === 0 ? <span className="not-found-text">No Products Found For {ailment.name}</span> : "Products that may help " + ailment.name;

    return (
        <Container>
            <SectionLabelOne paddingBottom={20}>
                {forHerb? herbTitle : ailmentTitle}


            </SectionLabelOne>
            {loading ? <LoadingSpinner2 color={"#05AA97"} /> :
                <div className="product-grid">
                    {renderProducts}
                </div>}
            {/* <LoadMore>
                See More Products
            </LoadMore> */}


        </Container>
    )
}

ProductsSectionGrid.defaultProps = {
    forHerb: false,
    forAilment: false,
    herb: {
        mongodb_id: "",
        name: "",
        _id: ""
    },
    ailment: {
        mongodb_id: "",
        name: "",
        _id: ""
    }
}


ProductsSectionGrid.propTypes = {
    forHerb: PropTypes.bool,
    forAilment: PropTypes.bool,
    herb: PropTypes.shape({
        mongodb_id: PropTypes.string,
        _id: PropTypes.string,
        name: PropTypes.string
    }),
    ailment: PropTypes.shape({
        mongodb_id: PropTypes.string,
        _id: PropTypes.string,
        name: PropTypes.string
    })
}

export default ProductsSectionGrid