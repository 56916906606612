import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Container } from "./styles"
import { APP_PAGE } from "../../Layout/routes"
import { Link } from "gatsby"

function SignUpMessageBarWithCount({
  hrefPath = APP_PAGE,
  countLeft = false,
  visible = true,
  callbackIfZero = () => console.log("this is callback if zero prop"),
}) {
  const [count, setCount] = useState(null)

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && countLeft !== count) {
      handleCount(isSubscribed)
    }
    return () => (isSubscribed = false)
  }, [countLeft])
  
  const handleCount = isSubscribed => {
    if (!count || count !== countLeft) {
      setCount(countLeft)
    }

    if (isSubscribed && countLeft && countLeft < 0) {
      callbackIfZero()
    }
  }

  return (
    <Container visible={!count ? false : visible}>
      <span className="message-span-with-count">
        You have <b>{count}</b> free member-only articles left this month.{" "}
        <Link to={APP_PAGE}>
          Sign up for our app and get unlimited access it's free
        </Link>
      </span>
    </Container>
  )
}

SignUpMessageBarWithCount.propTypes = {
  href: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  countLeft: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.bool.isRequired,
  ]),
  callbackIfZero: PropTypes.func.isRequired,
}

export default SignUpMessageBarWithCount
