import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const ToolStyled = styled.div`
    width: 100%;
    height: 31px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    background-size: cover;
    position: relative;
    .references-label{
        user-select: none;
        color: var(--LinkColor);
        font-family: var(--Font1);
        font-size: medium;
        cursor: pointer;
        :hover{
            text-decoration: underline;
        }
    }
`

const Popper = styled.div`
    position: absolute;
    top: 45px;
    left: 0px;

    ${props => props.show ? "display: block; width: 100%;" : "display: none;"};


    .popper-span{
        ${props => props.show ? "display: block;" : "display: none;"};
        /* box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1); */
        position: absolute;
        width: 100%;
        background: white;
        min-height: 40px;
        border-radius: 4px;
        z-index: 80;
    }

    .caret{
        ${props => props.show ? "display: block;" : "display: none;"};
        border-top: 1px solid rgba(0, 0, 0, 0.10);
        border-left: 1px solid rgba(0, 0, 0, 0.10);
        position: absolute;
        width: 14px;
        height: 14px;
        background: red;
        top: -7px;
        left: 10px;
        background: white;
        z-index: 60;
        transform: rotate(45deg);
    }

    .popper-content{
        background: white;
        min-width: 100%;
        border-radius: 4px;
        height: 100%;
        ${props => props.show ? "display: flex; flex-direction: column;" : "display: none;"};
        box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
        height: 100%;
        z-index: 50;
        align-items: center;
        padding: 0px 10px 10px 10px;
        position: relative;
    }

 


`

const SectionTitle = styled.p`
    width: 100%;
    text-align: left;
    margin-top: 0px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: var(--Font1);
    margin-top: 5px;
    margin-bottom: 5px;
    user-select: none;
`

const StyledReference = styled.li`
    position: relative;
    font-size: 12px;
    font-family: var(--Font2);
`

const ReferenceList = styled.ul`
    width: 100%;
    position: relative;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-top: 0px;
    list-style: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
`


function ReferencePopper(props) {
    let [tool, setTool] = useState({
        icon: "",
        name: ""
    })


    let [showPopper, setShowPopper] = useState(false)



    let renderReferences = props.references.map((reference, index) => {
        return (<StyledReference key={index}>{reference}</StyledReference>)
    })




    return (
        <ToolStyled >
            <div onClick={() => setShowPopper(showPopper ? false : true)} className="references-label" >
                View References
            </div>
            <Popper show={showPopper}>
                <span className="popper-span">
                    <div className="caret"></div>
                    <div className="popper-content">
                        <SectionTitle>
                            References
                        </SectionTitle>
                        <ReferenceList>
                            {renderReferences}
                        </ReferenceList>

                    </div>
                </span>
            </Popper>


        </ToolStyled>
    )
}

ReferencePopper.defaultProps = {
    id: ""
}

export default ReferencePopper;



