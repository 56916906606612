import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types'

import { makeStyles } from "@material-ui/core/styles"

const Star = styled.div`
    position: relative;
    cursor: pointer;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: flex-start;

`

const StarWrapper = styled.div`
    width: ${props => props.fontSize === "large"?  `165px` : "120px" };

    display: grid;
    grid-gap: 5px;
    grid-template-columns: ${props => props.fontSize === "large"?  `25px 25px 25px 25px 25px;` : "15px 15px 15px 15px 15px;" };
`

const useStyles = makeStyles(theme => ({
    star: {
        color: "#F2C366"
    }
  }))

const RenderRating = ({value, fontSize}) => {
    const classes = useStyles()

    return(
        <StarWrapper fontSize={fontSize}>
            <Star>

            {value >= 1 ? <StarIcon fontSize={fontSize} className={classes.star} /> : <StarBorderIcon fontSize={fontSize} className={classes.star} />}
            </Star>
            <Star>

            {value >= 2 ? <StarIcon fontSize={fontSize} className={classes.star} /> : <StarBorderIcon fontSize={fontSize} className={classes.star} />}
            </Star>
            <Star>

            {value >= 3 ? <StarIcon fontSize={fontSize} className={classes.star} /> : <StarBorderIcon fontSize={fontSize} className={classes.star} />}
            </Star>
            <Star>

            {value >= 4 ? <StarIcon fontSize={fontSize} className={classes.star} /> : <StarBorderIcon fontSize={fontSize} className={classes.star} />}
            </Star>
            <Star>

            {value >= 5 ? <StarIcon fontSize={fontSize} className={classes.star} /> : <StarBorderIcon fontSize={fontSize} className={classes.star} />}
            </Star>

        </StarWrapper>
    )
}


RenderRating.defaultProps = {
    fontSize: 'default',
    value: 0
}


RenderRating.propTypes = {
    fontSize: PropTypes.string,
    value: PropTypes.number.isRequired
}

export default RenderRating