import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { BreakpointTablet, SectionLabelOne } from '../Layout/GlobalStyle';
import LoadingSpinner2 from '../common/LoadingContainer/LoadingSpinner2';
import HerbByAilmentItem from './HerbByAilmentItem';
import PropTypes from 'prop-types'
import { getHerbsByAilmentID } from '../../services/herb.services';




const Container = styled.div`
    /* border-bottom: 1px solid #ccc; */
    /* min-height: 316px; */
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;

    /* .products-section-title{
        font-family: var(--Font1);
        text-transform: uppercase;
        font-weight: bold;
        color: #222220;
        padding-bottom: 25px;
    } */

    .product-grid{
        display: grid;
        grid-template-columns: repeat(auto-fill, 130px);
        grid-gap: 25px;
        width: 100%;
    }

    @media screen and (max-width: ${BreakpointTablet + 'px'}) {
        padding-top: 0px;
        .products-section-title{
            font-size: 16px;
            padding-bottom: 15px;
        }

        .product-grid{
            grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
        }
    }

`


const LoadMore = styled.button`
    height: 34px;
    width: 100%;
    margin-top: 14px;
    font-size: 16px;
    font-family: var(--Font1);
    background: var(--Color2);
    color: white;
    text-transform: uppercase;
    border: 0px solid transparent;
`

const defaultLimit = 5;

const HerbsByAilmentSection = ({ailment}) => {
    const [herbs, setHerbs] = useState([])
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(defaultLimit)


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            loadByAilment()
           
        }
        return () => isSubscribed = false;
    }, [ailment && ailment._id, ailment && ailment.mongodb_id])

    console.log(ailment, 'this is ailment')

    console.log(herbs, 'these are herbs')
  


 

    const loadByAilment = () => {
            setLoading(true)
 

                getHerbsByAilmentID(ailment.mongodb_id, `?limit=${limit}`)
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setHerbs(res.data.herbs)
                    setCount(res.data.count)
                }
            })
            .catch(err => {
                setLoading(false)
                throw err
            })
        
    }





    let renderHerbs = herbs.map((p, i) => {
        return (
            
            <HerbByAilmentItem item={p} key={i}  />
        )
    })


const sectionTitle = loading && ailment.name !== "" ? <span className="loading-place-holder-text">
Looking for herbs that may help {ailment.name}
</span> : loading && !ailment.name ? `Looking for herbs` : !loading && herbs.length === 0 ? <span className="not-found-text">No Herbs Found For {ailment.name}</span> : "Herbs that may help " + ailment.name;

    return (
        <Container>
            <SectionLabelOne paddingBottom={20}>
                {sectionTitle}


            </SectionLabelOne>
            {loading ? <LoadingSpinner2 color={"#05AA97"} /> :
                <div className="product-grid">
                    {renderHerbs}
                </div>}
            {/* <LoadMore>
                See More Products
            </LoadMore> */}


        </Container>
    )
}

HerbsByAilmentSection.defaultProps = {
    ailment: {
        mongodb_id: "",
        name: "",
        _id: ""
    }
}


HerbsByAilmentSection.propTypes = {
    ailment: PropTypes.shape({
        mongodb_id: PropTypes.string,
        _id: PropTypes.string,
        name: PropTypes.string
    })
}

export default HerbsByAilmentSection