import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { BreakpointTablet, BreakpointMobile, theme } from '../Layout/GlobalStyle'
import VisibilitySensor from 'react-visibility-sensor';
import { getHerbBySlug, getHerbMainPictureByHerbID } from '../../services/herb.services'

import { HERB } from '../Layout/routes'
import PropTypes from 'prop-types'

export const SmallArticleItemStyled = styled.div`
    display: flex;
    height: 70px;
    cursor: pointer;

    a{
        display: inherit;
        height: inherit;

        .small-column-item-title{
            color: var(--TextTitleColor1);
        }

        :hover{
            text-decoration: underline;
        
    }
    }

    .small-column-item-header{

    }

    .entire-meta-link{
        display: flex;
        width: 100%;
    }   

    .small-item-wrap{
        padding-left: 15px;
        width: 100%;
    }

    .small-column-item-title{
        font-size: 16px;
        margin-top: 0px;
        color: black;
        font-family: var(--Font1);
        line-height: 24px;
        margin-bottom: 0px;
        color: var(--TextTitleColor1);
        font-weight: 400;
        font-weight: bold;
        width: 100%;
        padding-bottom: 0px;
        transition: 0.2s ease-in-out;
    }

    .small-item-details{
        font-size: 12px;
        line-height: 14px;
        text-overflow: ellipsis;
        font-family: var(--Font2);
        margin-top: 2px;
        color: black;
        width: 90%;
    }   


    :hover{
        .small-column-item-title{
            color: var(--TextTitleColor1);
        }
        
    }

    

    /* @media only screen and (max-width: ${BreakpointTablet + 'px'}){
        height: initial;
        .entire-meta-link{
           
        }
        .small-item-wrap{
            
        }

        

        
    } */
/* 
    @media only screen and (max-width: ${BreakpointMobile + 'px'}) {
        .entire-meta-link{
            width: 100%;
            flex-direction: column;
            align-items: left;
        }

        .small-column-item-title{
            text-align: left;
            font-size: large;
            line-height: 30px;
            font-weight: bold;
        }

        .small-item-wrap{
            padding-top: 10px;
            padding-left: 0px;
        }

        .excerpt{
            display: none;
        }
    } */

`

export const PostFeaturedImageWrap = styled.div`
    display: block;
    min-width: 65px;
    min-height: 65px;
    border-radius: 4px;
    overflow: hidden;


    .image-source{
        width: 65px;
        overflow: hidden;

        height: 65px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.01);

        img{
            height: 65px;
            width: 65px;
            object-fit: cover;
            border-radius: 4px;


        }
    }

        
    
/* 
    @media only screen and (max-width: ${BreakpointMobile + 'px'}) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: auto;
   
            .image-source{
                min-width: inherit;
                max-width: inherit;
                height: 200px;
                
                
            }
        
    } */

`

 function HerbMediumListItem(props) {
    const {herb} = props;
    console.log(props, 'this is props')
    const  {slug, name, latinName} = herb;
    const [loading, setLoading] = useState(false)
    const [picture, setPicture] = useState(null)
    const [hasCheckedForPicture, setHasCheckedForPicture] = useState(false)

    const loadData = () => {
      setLoading(true)
      getHerbMainPictureByHerbID(herb._id)
          .then(res => {
              if (res.status === 200) {
                  setLoading(false)

                      setPicture(res.data)

                  
                  setHasCheckedForPicture(true)

              }
          })
          .catch(err => {
              setHasCheckedForPicture(true)
              setLoading(false)
              throw err
          })
  }

  const onVisibilityChange = (isVisible) => {
    if (isVisible === true && !picture && !hasCheckedForPicture && !loading) {
        loadData(true)
    }
}
    return (
        <SmallArticleItemStyled >
            <Link to={`${HERB}/${slug}`} className="entire-meta-link">
            <VisibilitySensor onChange={(e) => onVisibilityChange(e)}>

            <PostFeaturedImageWrap src={picture && picture.src? picture.src: theme.defaultHerbListIcon} >
                <div  className="image-source"  >
                    <img alt={picture && picture.alt_text? picture.alt_text: `${herb.name} thumbnail`} height={"auto"} src={picture && picture.src? picture.src: theme.defaultHerbListIcon} />
                </div>
            </PostFeaturedImageWrap>
            </VisibilitySensor>

                <div className="small-item-wrap">
                    <div className="small-column-item-header">
                        <h3 className="small-column-item-title"> {name} </h3>
                    </div>
                    <p className="small-item-details">
                      {latinName}
                    </p>
                </div>
            </Link>   
        </SmallArticleItemStyled>
    )
}


HerbMediumListItem.defaultProps = {
  herb: {
    slug: "",
    name: "",
    latinName: ""
  }
};

HerbMediumListItem.propTypes = {
    herb: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        latinName: PropTypes.string.isRequired
    })
}


export default HerbMediumListItem;