import React, { useState, useEffect, useRef } from "react"
import { theme } from "../../Layout/GlobalStyle"
import PropTypes from "prop-types"
import { getProductPictureByProductID } from "../../../services/product.services"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { ShoppingCart } from "@material-ui/icons"
import {
  ProductItemWrapper,
  ProductCarouselItem,
  BuyProductButton,
} from "./styles"
import { Link } from "gatsby"
import { PRODUCT } from "../../Layout/routes"

function ProductItem(props) {
  const {
    item: { name, _id: productID, amazonAffiliateLink, slug },
  } = props
  const [loading, setLoading] = useState(false)
  const [picture, setPicture] = useState("")
  const [hasCheckedForPicture, setHasCheckedForPicture] = useState(false)
  const buttonRef = useRef()
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && productID) {
      loadData()
    }

    return () => (isSubscribed = false)
  }, [!picture && !hasCheckedForPicture && productID])

  const loadData = () => {
    setLoading(true)
    getProductPictureByProductID(productID)
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          if (res.data && res.data.picture) {
            setPicture(res.data.picture)
          }
          setHasCheckedForPicture(true)
        }
      })
      .catch(err => {
        setHasCheckedForPicture(true)
        setLoading(false)
        throw err
      })
  }

  return (
    <ProductItemWrapper>
      <ProductCarouselItem  src={picture}>
        <Link to={`${PRODUCT}/${slug}`}>

        <div className="product-image">
          <img
        
            style={{ width: "inherit", height: "inherit" }}
            alt={name.substring(0, 100)}
            src={picture ? picture : theme.defaultProductListIcon}
          />
          <div className="color-overlay"></div>
        </div>
        <div className="product-information">
          <div className="product-title">{name.substring(0, 50) + "..."}</div>
        </div>
        </Link>

        <OutboundLink
          className="herb-button-outbound-link"
          hrefLang="en-us"
          href={amazonAffiliateLink.replace("http://", "https://")}
          target="_blank"
        >
          <BuyProductButton
            startIcon={<ShoppingCart fontSize="small" />}
            variant={"contained"}
            color="secondary"
            disableElevation
            fullWidth
            ref={buttonRef}

          >
            Buy From Amazon
          </BuyProductButton>
        </OutboundLink>
      </ProductCarouselItem>
    </ProductItemWrapper>
  )
}

ProductItem.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    amazonAffiliateLink: PropTypes.string.isRequired,
  }),
}

export default ProductItem
