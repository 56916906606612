import React from 'react'
import styled from 'styled-components';


export const LoadingSpinnerWrapper = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Container = styled.div`


  width: ${props => props.size? `${props.size}px;` : '80px;'};
  height:  ${props => props.size? `${props.size}px;` : '80px;'};
  display: inline-block;
  position: relative;
 
div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  background: ${props => props.color && `${props.color};`};

  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

`
function LoadingSpinner2(props) {
  let {size, color} = props;
    return (

    <Container color={props.color} size={props.size}>
        <div></div><div></div><div></div><div></div>
    </Container>
    )
}



LoadingSpinner2.defaultProps = {
    color: '#ccc',
    size:  80
};


export default LoadingSpinner2;