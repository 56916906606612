import axios from "./axios";
import { getHeaders } from "../components/Layout";


export const getArticlesByHerbID = (herbid, query) => {
    return axios.get(`/api/blog-articles/herb/${herbid}${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getArticlesByAilmentID = (ailmentid, query) => {
    return axios.get(`/api/blog-articles/ailment/${ailmentid}${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const getArticlesByProductID = (productid, query) => {
    return axios.get(`/api/blog-articles/product/${productid}${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}
