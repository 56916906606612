import React, { useState, useEffect, useContext, forwardRef } from 'react'
import { navigate } from "@reach/router"

import { LabelTypeOne, SectionLabelOne, PageParagraphTypeOne, ScrollToAnchor } from '../Layout/GlobalStyle'
import { getHerbReviewsByHerbID, getReviewByHerbAndUserID } from '../../services/herb-review.services'
import HerbReviewItem from './HerbReviewItem/index'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CREATE_HERB_REVIEW } from '../Layout/routes'
import AuthUserContext from '../../providers/AuthProvider'
import withLocation from '../common/withLocation'
import PropTypes from 'prop-types'
import RenderRating from '../common/Rating'
import RatingBar, { RatingBarsGrid } from '../RatingBar/RatingBar'

const ContainerWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`


const HerbRatingWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;
    .rating-out-of-5{
        color: #4D4D4D;
        font-size: 16px;
        font-family: var(--Font1);
        font-weight: bold;
    }

    .rating-count{
        font-size: 16px;
        font-family: var(--Font1);
        font-weight: bold;
        color: rgb(77, 77, 77);
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .rating-stars-and-out-of-5{
        display: flex;
        align-items: center;
    }
`

const Container = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;


    .idAchor{
        background: black;
        width: 20px;
        height: 20px;
    }
`


const HerbReviewsList = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-bottom: 15px;
`


const herbReviewStyles = makeStyles({
    buttonIntialText: {
        fontFamily: "Roboto",
        textTransform: 'initial',
        fontWeight: 'bold'
    },
    loadMoreReviewsText: {
        fontSize: 14,
        textDecoration: "underline",
        color: "var(--LinkColor)",
        fontFamily: "var(--Font1NotCondensed)",
        cursor: "pointer",
        fontWeight: 'initial'

    },
    loadingMoreReviewsText: {
        fontSize: 14,
        color: "var(--GreyColorOne)",
        fontFamily: "var(--Font1NotCondensed)",
        fontWeight: 'initial'
    },
    buttonTypeOne: {
        paddingLeft: 10,
        paddingRight: 10,
        borderStyle: "solid",
        borderWidth: 1,
        fontSize: 13,
        minHeight: 29,
        borderColor: "#ADB1B8 #A2A6AC #8D9096",
        textTransform: 'initial',
        fontFamily: "var(--Font1NotCondensed)",
        background: "linear-gradient(to bottom,#f7f8fa,#e7e9ec)"
    },
    paragraphTypeOne: {
        fontSize: 13,
        color: '#4D4D4D',
        fontFamily: "var(--Font1NotCondensed)",
        marginTop: 0
    }


});


// TO DO
// Grab herb reviews from database
// Map out reviews
// Allow user to mark review as helpful or not

let defaultLimit = 4

const HerbReviews = ({forwardRef, herbRatingInfo, herb}) => {

    const classes = herbReviewStyles()
    const [count, setCount] = useState(0)
    const [limit, setLimit] = useState(defaultLimit)
    const [herbReviews, setHerbReviews] = useState([])
    const [loadingReviews, setLoadingReviews] = useState(false)
    const [fetchingMore, setFetchingMore] = useState(false)
    const { user: currentUser, isTheUserLoggedIn, openSignInModal } = useContext(AuthUserContext)

    const [usersReview, setUsersReview] = useState({
        _id: ""
    })


    // START OF USE EFFECTS

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && herb.mongodb_id) {
            loadHerbReviews()
        }
        return () => {
            isSubscribed = false;
        }
    }, [herb.mongodb_id])


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && herb.mongodb_id) {
            fetchMoreHerbReviews()
        }
        return () => {
            isSubscribed = false;
        }
    }, [herb.mongodb_id && defaultLimit !== limit])


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && herb.mongodb_id && isTheUserLoggedIn) {
            loadReviewFromCurrentUser()
        }

        return () => isSubscribed = false;
    }, [herb.mongodb_id && isTheUserLoggedIn === true && herbReviews, herbReviews])

    // END OF USE EFFECTS


    const loadHerbReviews = () => {
        setLoadingReviews(true)
        getHerbReviewsByHerbID(herb.mongodb_id, `?limit=${limit}`)
            .then(res => {
                if (res.status === 200) {
                    setHerbReviews(res.data.herbReviews)
                    setCount(res.data.count)
                    setLoadingReviews(false)


                }
            })
            .catch(err => {
                setLoadingReviews(false)

                throw err
            })
    }


    const fetchMoreHerbReviews = () => {
        setFetchingMore(true)
        getHerbReviewsByHerbID(herb.mongodb_id, `?limit=${limit}`)
            .then(res => {
                // console.log('COMPLETED fetching more reviews in herb reviews')

                if (res.status === 200) {
                    setHerbReviews(res.data.herbReviews)
                    setCount(res.data.count)
                    setFetchingMore(false)


                }
                // console.log(res.data, 'this is res.data in herb reviews when fetching')
            })
            .catch(err => {

                setFetchingMore(false)

                throw err
            })
    }

    const loadReviewFromCurrentUser = () => {
        getReviewByHerbAndUserID(currentUser.id, herb.mongodb_id)
            .then(res => {
                if (res.status === 200 && res.data) {
                    setUsersReview(res.data)



                }
            })
            .catch(err => {
                throw err
            })
    }





    const renderItems = herbReviews.map((item, index) => {
        return (
            <HerbReviewItem loadHerbReviews={loadHerbReviews} key={index} item={item} />
        )
    })

    const handleNavigateCreateHerbReview = () => {
        let redirectPath = `${CREATE_HERB_REVIEW}?id=${herb.mongodb_id}`
        if (isTheUserLoggedIn) {
            navigate(redirectPath)
        } else {
            openSignInModal("Review this herb", redirectPath)
        }
    }



    return (
        <ContainerWrapper ref={forwardRef}>

            <ScrollToAnchor id="herbReviewSection" />


            <Container>
                <SectionLabelOne paddingBottom={10}>{loadingReviews ? "Looking for reviews" : count === 0 ? <div className="not-found-text"> No reviews found for {herb.name}</div> : `${herb.name} Reviews`} </SectionLabelOne>
                <HerbRatingWrapper>
                    <div className="rating-stars-and-out-of-5">

                        <RenderRating fontSize="large" value={herbRatingInfo.herbReviewAverage} />
                        <div className="rating-out-of-5">
                            {herbRatingInfo.herbReviewAverage} out of 5
                        </div>
                    </div>
                    <div className="rating-count">
                        {herbRatingInfo.herbReviewCount ? `${herbRatingInfo.herbReviewCount} ${herbRatingInfo.herbReviewCount === 0? 'ratings': herbRatingInfo.herbReviewCount ===1? 'rating': 'ratings'}` : `0 ratings`}
                    </div>
                    <RatingBarsGrid>
                        <RatingBar herbID={herb.mongodb_id}  targetRatingValue={1} />
                        <RatingBar herbID={herb.mongodb_id}   targetRatingValue={2} />
                        <RatingBar  herbID={herb.mongodb_id}  targetRatingValue={3} />
                        <RatingBar herbID={herb.mongodb_id}  targetRatingValue={4} />
                        <RatingBar herbID={herb.mongodb_id}  targetRatingValue={5} />
                    </RatingBarsGrid>

                </HerbRatingWrapper>

            {usersReview._id ? <Container>
                <SectionLabelOne>
                    Your Review for {herb.name}
                </SectionLabelOne>
                <HerbReviewItem loadHerbReviews={loadHerbReviews} item={usersReview} />
            </Container> :
                <Container >
                    <SectionLabelOne>
                        Help others by leaving a review
                </SectionLabelOne>
                    <p className={classes.paragraphTypeOne}>Share your experience with this herb to help other people</p>
                    <Button onClick={() => handleNavigateCreateHerbReview()} disableElevation variant="contained" color="secondary" className={classes.buttonIntialText}>
                        Write a review for this herb
                    </Button>

                </Container>}
                <LabelTypeOne className={"showing-herb-review-count"}>
                    {loadingReviews && count === 0 ? "" :
                        limit >= count && count !== 0 ? `Showing ${count} of ${count} reviews` :
                            count !== 0 ? `Showing ${limit} of ${count} reviews` :
                                ''
                    }
                </LabelTypeOne>
                <HerbReviewsList>
                    {renderItems}
                </HerbReviewsList>
                { fetchingMore? <h6 className={classes.loadingMoreReviewsText}>Loading more reviews</h6> : count > limit && !loadingReviews?   <h6 onClick={() => setLimit(limit + 5)} className={classes.loadMoreReviewsText}>
                    Read more reviews on <b>{herb.name}</b> 
                </h6>: ""}
              

            </Container>
        </ContainerWrapper>

    )
}




HerbReviews.propTypes = {
    herb: PropTypes.shape({
        mongodb_id: PropTypes.string.isRequired
    }),
    herbRatingInfo: PropTypes.shape({
        herbReviewCount: PropTypes.number.isRequired,
        herbReviewAverage: PropTypes.number.isRequired
    })
}

export default React.forwardRef((props, ref) => { return <HerbReviews {...props} forwardRef={ref} /> })