import React from "react"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { getHerbsByHerbFamilyID } from "../../services/herb.services"
import { SectionLabelOne } from "../Layout/GlobalStyle"
import HerbMediumListItem from "../HerbMediumListItem"
import styled from "styled-components"
import { HerbInfoSectionColumn } from "../Herb"
import SmallArticleItem from "../Blog/SmallArticleItem"
import { getArticlesByHerbID } from "../../services/blog.services"

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`

function BlogArticlesByHerb(props) {
  const [loading, setLoading] = useState(false)
  const { herb } = props
  const { herbFamily: herbFamilyID, name, mongodb_id: herbId } = herb
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(5)
  const [blogArticles, setBlogArticles] = useState([])
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && herbId) {
      loadHerbArticles(isSubscribed)
    }

    return () => (isSubscribed = false)
  }, [herbId])

  const loadHerbArticles = (isSubscribed) => {
    setLoading(true)
    getArticlesByHerbID(herbId, `?limit=${limit}`)
      .then(res => {
        if (res.status === 200 && res.data && isSubscribed) {
          setBlogArticles(res.data.articles)
          setCount(res.data.count)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)

        throw err
      })
  }

  const renderItems = blogArticles.map((item, index) => {
    return <SmallArticleItem key={index} {...item} />
  })
  return (
    <HerbInfoSectionColumn>
      <SectionLabelOne>
        {loading
          ? "Looking for related articles"
          : blogArticles.length
          ? `Blog articles for ${name}`
          : null}
      </SectionLabelOne>
      <List>{renderItems}</List>
    </HerbInfoSectionColumn>
  )
}

BlogArticlesByHerb.propTypes = {
  herb: PropTypes.shape({
    herbFamily: PropTypes.string,
    name: PropTypes.string.isRequired,
    mongodb_id: PropTypes.string.isRequired,
  }),
}

export default BlogArticlesByHerb
