import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { BreakpointTablet } from '../../Layout/GlobalStyle'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import { getHerbBySlug } from '../../../services/herb.services';
import { HERB } from '../../Layout/routes'

const ProductItemWrapper = styled.div`
    display: block;
    position: relative;
    overflow: hidden;

    .out-bound-link{
        position: inherit;
    }
`


const ProductCarouselItem = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .product-image{
        width: 100%;
        height: 130px;
        border-radius: 4px;
        background-size: cover;
        position: relative;
        background-position: 50%;
        background-repeat: no-repeat;

        background-image: url(${props => props.theme.defaultHerbListIcon});      
        ${props => props.src ? `background-image: url(${props.src});` : `background-image: url(${props => props.theme.defaultHerbListIcon});`};
    }

    .product-title{
        font-size: 13px;
        color: #222220;
        font-family: var(--Font1);
        line-height: 20px;

        :hover{
            text-decoration: underline;
        }
    }

    .product-information{
        height: 40px;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: ${BreakpointTablet + 'px'}) {
        .product-image{
            height: 102px;   
        }

        

        padding: 6px;
    }

    :hover{
        .product-title{
            color: var(--Color2);
        }
        
    }

`

function HerbByAilmentItem(props) {
  const {item: {name, slug, _id}} = props;
  const [loading, setLoading] = useState(false)
  const [picture, setPicture] = useState("")
  const [hasCheckedForPicture, setHasCheckedForPicture] = useState(false)

  useEffect(() => {
    let isSubscribed = true;
    if(isSubscribed && slug){
      loadData()
    }

    return () => isSubscribed = false;

  },[!picture && !hasCheckedForPicture && slug !== ""])

  const loadData = () => {
    setLoading(true)
    getHerbBySlug(slug, '?picturesOnly=true')
        .then(res => {
            if (res.status === 200) {
                setLoading(false)
                console.log(res.data)
                if (res.data && res.data.pictures && res.data.pictures[0]) {
                    setPicture(res.data.pictures[0].src)

                }
                setHasCheckedForPicture(true)

            }
        })
        .catch(err => {
            setHasCheckedForPicture(true)
            setLoading(false)
            throw err
        })
}




  return (
    <ProductItemWrapper>

    <Link to={`${HERB}/${slug}`} >

    <ProductCarouselItem src={picture}  >
        <div className="product-image">
            {picture?  <img style={{opacity: 0, width: "inherit", height: "inherit"}} alt={name.substring(0, 100)} src={picture} /> : ""}
            <div className="color-overlay">

            </div>
        </div>
        <div className="product-information">
            <div className="product-title">
                {name}
            </div>
        </div>
    </ProductCarouselItem>
</Link>
</ProductItemWrapper>

  )
}

HerbByAilmentItem.propTypes = {
  item: PropTypes.shape ({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  })
}

export default HerbByAilmentItem
