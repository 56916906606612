import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { GlobalStyle, BreakpointTablet, BreakpointMobile } from '../../Layout/GlobalStyle'
import { BLOG_POST } from '../../Layout/routes'


export const SmallArticleItemStyled = styled.div`
    display: flex;
    height: 70px;

    .small-column-item-header{

    }

    .entire-meta-link{
        display: flex;
        width: 100%;
    }   

    .small-item-wrap{
        padding-left: 15px;
        width: 100%;
    }

    .small-column-item-title{
        font-size: 14px;
        margin-top: 0px;
        color: black;
        font-family: var(--Font1);
        font-weight: bold;
        line-height: 20px;
        margin-bottom: 0px;
        width: 100%;
        padding-bottom: 0px;
        transition: 0.2s ease-in-out;
    }

    .small-item-details{
        font-size: 12px;
        line-height: 14px;
        text-overflow: ellipsis;
        font-family: var(--Font2);
        margin-top: 2px;
        color: black;
        width: 90%;
    }   


    :hover{
        .small-column-item-title{
            color: var(--Color2);
        }
        
    }

    @media only screen and (max-width: ${BreakpointTablet + 'px'}){
        height: initial;
        .entire-meta-link{
           
        }
        .small-item-wrap{
            
        }

        

        
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}) {
        .entire-meta-link{
            width: 100%;
            flex-direction: column;
            align-items: left;
        }

        .small-column-item-title{
            text-align: left;
            font-size: large;

            line-height: 30px;
            font-weight: bold;
        }

        .small-item-wrap{
            padding-top: 10px;
            padding-left: 0px;
        }

        .excerpt{
            display: none;
        }
    }

`

export const PostFeaturedImageWrap = styled.div`
    display: block;
    min-width: 65px;
    min-height: 65px;
    border-radius: 4px;
    overflow: hidden;

    .image-source{
        background-position: 50%;
        min-width: 65px;
        ${props => props.src?`background-image: url(${props.src});`: 'background: grey;'};
        background-size: cover;
        min-height: 65px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.01);


    }

        
    

    @media only screen and (max-width: ${BreakpointMobile + 'px'}) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: auto;
   
            .image-source{
                min-width: inherit;
                max-width: inherit;
                height: 200px;
                
                
            }
        
    }

`

function SmallArticleItem(props) {
    // let limitedExcerpt = props.excerpt.replace(/<p[^>]*>/g, "").replace('</p>', '').substring(0, 22)
    // let sourceURL = props.featuredImage && props.featuredImage.sourceUrl? props.featuredImage.sourceUrl : props.featured_media && props.featured_media.source_url ? props.featured_media.source_url : placeHolderPostPhoto
    // let altText = props.featuredImage && props.featuredImage.altText? props.featuredImage.altText : props.featured_media && props.featured_media.alt_text ? props.featured_media.alt_text : "M10 Marketing Firm Post"
    console.log(props, 'this is props')
    let limitedExcerpt = props.metaDescription? props.metaDescription.substring(0, 22): "";
    let sourceURL = props.ogImage1200x628;
    let altText = ""
    return (
        <SmallArticleItemStyled>
            <Link to={`${BLOG_POST}/${props.slug}`} className="entire-meta-link">
            <PostFeaturedImageWrap src={sourceURL}>
                <div className="image-source" height={'auto'} alt={altText} src={sourceURL} />
            </PostFeaturedImageWrap>
                <div className="small-item-wrap">
                    <div className="small-column-item-header">
                        <h3 className="small-column-item-title"> {props.title} </h3>
                    </div>
                    <p className="small-item-details">
                        {limitedExcerpt}
                    </p>
                </div>
            </Link>   
        </SmallArticleItemStyled>
    )
}




export default SmallArticleItem;