import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Rating from '../../common/Rating'
import StarBorderIcon from '@material-ui/icons/StarBorder';
import UserOfReview from './UserOfReview';
import AilmentTag from '../../common/AilmentTag'
import { getHerbHelpfulReviewsByReviewID, markReviewAsHelpfulByHerbReviewID, deleteHerbReviewsByReviewID, getAbusedHerbReviewsByReviewID, reportHerbReviewAsAbused, getReviewByHerbAndUserID, deleteHelpfulHerbReview, deleteAbusedHerbReviewByID } from '../../../services/herb-review.services'
import moment from 'moment'
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import AuthUserContext from '../../../providers/AuthProvider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Loadable from 'react-loadable';
import { navigate } from "@reach/router"
import { LOGIN } from '../../Layout/routes';
import ErrorsContext from '../../../providers/ErrorsProvider';
import UndoIcon from '@material-ui/icons/Undo';
import { BreakpointMobile } from '../../Layout/GlobalStyle';
import PropTypes from 'prop-types'

const Viewer = Loadable({
    loader: () => import('react-viewer'),
    loading: () => <div></div>,
});

const useStyles = makeStyles((theme) => ({
    loadMoreReviewsText: {

    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    undoHelpfulButton: {
        fontFamily: "var(--Font1NotCondensed)",
        color: 'var(--ErrorColor)',
        fontWeight: 'bold',
        background: 'white',
        textTransform: 'initial',
        height: 29,
        fontSize: 13,
        paddingLeft: 4,
        paddingRight: 4
    },
    alreadyReported: {
        fontStyle: 'italic',
        fontSize: 12,
        fontWeight: 'bold'
    },
    alreadyFoundHelpful: {
        display: 'flex',
        alignItems: 'center'

    },
    numberOfPeople: {
        fontWeight: 'bold',
        color: 'var(--Color1)',
        fontSize: 13

    },
    alreadyFoundHelpfulText: {
        fontStyle: 'italic',
        paddingRight: 4,
        fontSize: 12,
        fontWeight: 'bold'
    },
    verticalDivider: {
        marginLeft: 20,
        marginRight: 20
    },
    verticalDividerSmaller: {
        marginLeft: 15,
        marginRight: 15
    },
    dialogTitle: {
        fontFamily: 'Roboto !important',
        fontWeight: 'bold',
    },
    reportButtonForDialog: {
        fontFamily: "var(--Font1NotCondensed)",
        fontWeight: 'bold',
    },
    reportDialogText: {
        fontSize: 13
    },
    dialogFormWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 20,
        paddingTop: 20,
        paddingBottom: 20
    },
    cancelButton: {
        fontFamily: "var(--Font1NotCondensed)",
        color: '#4D4D4D',
        fontWeight: 'bold'

    },
    removeButton: {
        fontFamily: "var(--Font1NotCondensed)",
        color: 'var(--ErrorColor)',
        fontWeight: 'bold'
    },
    buttonTypeOne: {
        paddingLeft: 10,
        paddingRight: 10,
        borderStyle: "solid",
        borderWidth: 1,
        fontSize: 13,
        fontFamily: "var(--Font1NotCondensed)",
        minHeight: 29,
        borderColor: "#ADB1B8 #A2A6AC #8D9096",
        textTransform: 'initial',
        background: "linear-gradient(to bottom,#f7f8fa,#e7e9ec)",
        marginRight: 10
    },
    buttonTypeTwo: {
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 13,
        fontFamily: "var(--Font1NotCondensed)",
        height: 29,
        textTransform: 'initial'
    }
}));

export const PhotoGrid = styled.div`
    position: relative;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, 50px) ;
  
    height: 50px;

`


export const PhotoGridItem = styled.div`
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 50px;
    ${props => props.src ? `background-image: url(${props.src});` : "background: grey;"};
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 4px;
    cursor: pointer;

    :hover{
        border: 1px solid rgba(0, 0, 0, 0.6);
    }

`


const Container = styled.div`
    padding-top: 15px;
    .rating-and-title{
        display: flex;
        align-items: center;

        .title{
            font-weight: bold;
            font-family: var(--Font1);
            font-size: 14px;
            color: #4D4D4D;
        }

    }

    .reviewed-on-date{
        font-family: var(--Font1);
        color: var(--GreyColorOne);
        font-size: 14px;
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        .rating-and-title{
            display: flex;
            align-items: initial;

            flex-direction: column;
        }
    }
`


const Actions = styled.footer`
    padding-top: 10px;
    display: flex;
    align-items: center;
   
`

const AilmentsSection = styled.div`

    .ailments-it-helped-me-with{
        font-size: 14px;
        font-family: var(--Font1);
        color: var(--GreyColorOne);
        font-weight: bold;

    }

    .ailment-tag-wrapper{
        display: flex;
    }
    margin-bottom: 10px;



`

const PicturesSection = styled.div`
    margin-bottom: 10px;
`

const HelpfulText = styled.div`
 
   
    font-size: 11px;
    font-family: var(--Font1NotCondensed);
`

const ReviewParagraph = styled.p`
    font-family: var(--Font2);
    font-size: var(--ParagraphFontSize);
    margin-bottom: 10px;
    margin-top: 0px;
    width: 300px;
    color: #4D4D4D;
    line-height: var(--ParagraphLineHeight);
    font-family: var(--Font2);
    padding-top: 5px;
    padding-bottom: 5px;

`

let defaultAbusedReviewState = {
    created_at: "",
    user: "",
    herbReview: ""
}
function HerbReviewItem({item, loadHerbReviews, }) {
    let { errors, setErrors } = useContext(ErrorsContext)
    let { user: currentUser, isTheUserLoggedIn, openSignInModal } = useContext(AuthUserContext)
    const [imageViewerVisible, setImageViewerVisible] = useState(false)
    const [currentImage, setCurrentImage] = useState(0)
    const [herbReview, setHerbReview] = useState({
        _id: "",
        used_for_ailments: [],
        pictures: [],
        title: "",
        remedyType: "",
        rating: 0,
        herb: {
            name: ""
        },
        created_at: "",
        description: "",
        user: ""
    })

    let { title, rating, description, user, created_at, pictures } = herbReview;



    // DELETING REVIEW USE STATE
    let [deleting, setDeleting] = useState(false)
    let [deleted, setDeleted] = useState(false)
    let [removeDialogOpened, setRemoveDialogOpened] = useState(false)




    // ABUSED HERB REVIEW STATE
    let [reporting, setReporting] = useState(false)
    const [deletingAbusedHerbReview, setDeletingAbusedHerbReview] = useState(false)

    let [reportReviewDialogOpened, setReportReviewDialogOpened] = useState(false)
    let [reportState, setReportState] = useState({
        description: "",
        problemType: ""
    })

    let [usersAbusedReview, setUsersAbusedReview] = useState(defaultAbusedReviewState)

    //HELPFUL REVIEW USE STATE
    const [helpfulReviews, setHelpfulReviews] = useState([])
    const [loadingHelpfulReviews, setLoadingHelpfulReviews] = useState(false)
    const [currentUserFoundThisHelpful, setCurrentUserFoundThisHelpful] = useState(false)
    const [submittingReviewAsHelpful, setSubmittingReviewAsHelpful] = useState(false)
    const [deletingReviewAsHelpful, setDeletingReviewAsHelpful] = useState(false)
    const classes = useStyles()



    // START OF USE EFFECTS
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && item._id) {
            setHerbReview(item)
        }

        return () => isSubscribed = false;
    }, [item._id])

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && herbReview._id) {
            loadHelpfulReviews()

        }

        return () => isSubscribed = false;
    }, [herbReview._id !== ""])


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && herbReview._id && isTheUserLoggedIn) {
            loadAbusedHerbReviewFromUser()
        }

        return () => isSubscribed = false;
    }, [herbReview._id && isTheUserLoggedIn === true])

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && isTheUserLoggedIn && helpfulReviews) {
            let existingHelpfulReviewFromUser = helpfulReviews.find(item => item.user === currentUser.id)
            if (existingHelpfulReviewFromUser) {
                setCurrentUserFoundThisHelpful(true)

            }

        }

        return () => isSubscribed = false;
    }, [helpfulReviews.length && isTheUserLoggedIn === true])



    // END OF USE EFFECTS


    // START OF API CALLS

    const loadAbusedHerbReviewFromUser = () => {
        getAbusedHerbReviewsByReviewID(herbReview._id, `?user=${currentUser.id}`)
            .then(res => {
                if (res.status === 200 && res.data) {
                    if (res.data[0]) {
                        setUsersAbusedReview(res.data[0])
                    }
                }
            })
            .catch(err => {
                throw err
            })
    }




    const loadHelpfulReviews = () => {
        setLoadingHelpfulReviews(true)
        getHerbHelpfulReviewsByReviewID(herbReview._id)
            .then(res => {
                if (res.status === 200 && res.data) {
                    setHelpfulReviews(res.data)

                    // console.log(res.data, 'this is helpful reviews form res.data')

                }
                setLoadingHelpfulReviews(false)

            })
            .catch(err => {
                setLoadingHelpfulReviews(false)
                throw err

            })
    }




    const handleHelpfulReviewToggle = () => {
        // TO DO
        // REMOVE MARK REVIEW AS HELPFUL
        setSubmittingReviewAsHelpful(true)
        markReviewAsHelpfulByHerbReviewID(herbReview._id)
            .then(res => {
                if (res.status >= 200) {
                    loadHelpfulReviews()
                    setCurrentUserFoundThisHelpful(true)
                    setSubmittingReviewAsHelpful(false)

                }
            })
            .catch(err => {
                setSubmittingReviewAsHelpful(false)

                throw err
            })
    }

    const submitAbusedHerbReview = () => {
        setReporting(true)
        reportHerbReviewAsAbused({ ...reportState, reviewid: herbReview._id })
            .then(res => {
                // console.log(res, 'this is res for submit abused herb')
                if (res.status >= 200) {
                    setReporting(false)
                    setErrors({})
                    setReportReviewDialogOpened(false)
                    loadAbusedHerbReviewFromUser()
                }

            })
            .catch(err => {
                setReporting(false)
                setErrors(err.response.data)
                throw err
            })
    }

    const handleDeletingAbusedReview = () => {
        setDeletingAbusedHerbReview(true)
        deleteAbusedHerbReviewByID(usersAbusedReview._id)
            .then(res => {

                if (res.status >= 200) {
                    setDeletingAbusedHerbReview(false)
                    setUsersAbusedReview(defaultAbusedReviewState)
                }
            })
            .catch(err => {
                setDeletingAbusedHerbReview(false)
                throw err

            })
    }

    const handleDeletingReview = (id) => {
        setDeleting(true)
        deleteHerbReviewsByReviewID(id)
            .then(res => {
                if (res.status >= 200) {
                    setDeleting(false)
                    setDeleted(true)
                    setRemoveDialogOpened(false)
                    loadHerbReviews()
                    

                }

            })
            .catch(err => {
                setDeleting(false)

                throw err
            })
    }

    const handleDeleteHelpfulHerbReview = () => {
        setDeletingReviewAsHelpful(true)
        let helpfulReviewItem = helpfulReviews.find((helpfulReviewItem) => helpfulReviewItem.user === currentUser.id);
        if (helpfulReviewItem) {
            deleteHelpfulHerbReview(helpfulReviewItem._id)
                .then(res => {
                    if (res.status === 202) {
                        loadHelpfulReviews()
                        setCurrentUserFoundThisHelpful(false)
                        setDeletingReviewAsHelpful(false)

                    }
                })
                .catch(err => {
                    setDeletingReviewAsHelpful(false)

                    throw err
                })
        } else {
            setDeletingReviewAsHelpful(false)
            alert('could not find helpful review id')
        }
    }

    // END OF API CALLS


    const isReviewHelpfulHandler = () => {
        let windowPath = typeof window !== "undefined" ? window.location.pathname : false

        if (isTheUserLoggedIn) {
            handleHelpfulReviewToggle()
        } else {
            openSignInModal("Support This Review")
            // navigate(`${LOGIN}${windowPath ? `?redirect=${windowPath}` : ''}`)
        }
    }


    const reportReviewHandler = () => {
        let windowPath = typeof window !== "undefined" ? window.location.pathname : false

        if (isTheUserLoggedIn) {
            setReportReviewDialogOpened(true)
        } else {
            openSignInModal("Report This Review")
            // navigate(`${LOGIN}${windowPath ? `?redirect=${windowPath}` : ''}`)
        }
    }


    const REPORT_PROBLEM_TYPES = ["False Information", "Spam", "Hate Speech", "Something Else", "Nudity"]




    const handleDeleteClose = () => {
        setRemoveDialogOpened(false);
    };

    const handleReportClose = () => {
        setReportReviewDialogOpened(false);
    };



    let hasBeenReported = usersAbusedReview._id ? true : false;


    // START OF RENDER ITEMS

    const renderAilmentTags = herbReview.used_for_ailments ? herbReview.used_for_ailments.map((id, index) => {
        return (
            <AilmentTag key={index} id={id} />
        )
    }) : ""

    const renderProblemTypes = REPORT_PROBLEM_TYPES.map((text, index) => {
        return (<MenuItem key={index} value={text}>{text}</MenuItem>)
    })

    const handleOpenPicture = (index) => {
        setCurrentImage(index)
        setImageViewerVisible(true)
    }

    let renderPictures = pictures ? pictures.map((src, index) => {
        return (
            <PhotoGridItem onClick={() => handleOpenPicture(index)} key={index} src={src} >

            </PhotoGridItem>
        )
    }) : ""

    // END OF RENDER ITEMS


    // This is what we use to check if the user
    // is the owner of the herb review
    let isOwner = currentUser.id === item.user;


    let picturesForViewer = pictures ? pictures.map((item) => {
        let payload = {
            src: item
        }
        return payload
    }) : []



    return (
        <>
            {(typeof window !== 'undefined') && pictures ?
                <Viewer
                    visible={imageViewerVisible}
                    onClose={() => { setImageViewerVisible(false); }}
                    activeIndex={currentImage}
                    onMaskClick={() => setImageViewerVisible(false)}
                    images={picturesForViewer}
                    drag={false}
                    disableMouseZoom={true}
                    scalable={false}
                    loop={true}
                    zIndex={10000}
                    zoomable={false}
                    rotatable={false}
                    defaultScale={1}
                /> : ""
            }
            <Dialog
                open={reportReviewDialogOpened}
                onClose={handleReportClose}
                aria-labelledby={`report-herb-review-title${item._id}`}
                aria-describedby={`report-herb-review-description${item._id}`}
            >
                <DialogTitle style={{ color: "#4D4D4D", fontSize: 20, fontFamily: "Roboto" }} id={`report-herb-review-title${item._id}`}> Report abuse </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ fontFamily: "var(--Font2)", color: '#757575', fontSize: 13 }} id={`report-herb-review-description${item._id}`}>
                        If you find this content inappropriate and think it should be removed from the
                        findanherb.com site, let us know by filling out this form.
                    </DialogContentText>
                    <div className={classes.dialogFormWrapper}>
                        <FormControl
                            variant={"outlined"}
                            size={"small"}
                            disabled={reporting}
                            className={classes.formControl}>
                            <InputLabel id={`report-herb-review-select-label-${item._id}`}>Type of problem</InputLabel>
                            <Select
                                error={errors.problemType ? true : false}

                                labelId={`report-herb-review-select-label-${item._id}`}
                                id={`report-herb-review-select-menu-${item._id}`}
                                value={reportState.problemType}
                                onChange={(e) => setReportState({ ...reportState, problemType: e.target.value })}
                            >
                                {renderProblemTypes}
                            </Select>
                        </FormControl>
                        <TextField disabled={reporting} helperText={errors.description ? errors.description : ""} error={errors.description ? true : false} onChange={(e) => setReportState({ ...reportState, description: e.target.value })} value={reportState.description} size={"small"} id={`report-herb-review-description-${item._id}`} label="Describe the problem" variant="outlined" />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button disabled={reporting} className={classes.cancelButton} onClick={() => setReportReviewDialogOpened(false)} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={reporting} className={classes.reportButtonForDialog} onClick={() => submitAbusedHerbReview()} color="primary" autoFocus>
                        Report{reporting ? 'ing' : ''}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={removeDialogOpened}
                onClose={handleDeleteClose}
                aria-labelledby={`herb-delete-title${item._id}`}
                aria-describedby={`herb-delete-description${item._id}`}
            >
                <DialogTitle style={{ color: "#4D4D4D", fontSize: 20, fontFamily: "Roboto" }} id={`herb-delete-title${item._id}`}> Are you sure you want to remove <strong style={{ color: "var(--Color1)" }}>Your Review</strong>? </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ fontFamily: "var(--Font2)", color: '#757575' }} id={`herb-delete-description${item._id}`}>
                        Once you delete this review. It will be gone forever, which some people might need to read this review to find an herb that helps them
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={deleting} className={classes.cancelButton} onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={deleting} className={classes.removeButton} onClick={() => handleDeletingReview(item._id)} color="primary" autoFocus>
                        Remov{deleting ? 'ing' : "e"}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                !deleted ?
                    <Container>
                        <header >
                            <UserOfReview id={user} />
                            <div className={"rating-and-title"}>
                                <Rating value={rating} />
                                <div className="title">
                                    {title}
                                </div>

                            </div>
                            <div className="reviewed-on-date">
                                Reviewed on {moment(created_at).format("MMMM DD, YYYY")}
                            </div>



                        </header>

                        <ReviewParagraph>
                            {description}
                        </ReviewParagraph>
                        {item && item.used_for_ailments.length ?
                            <AilmentsSection>
                                <div className="ailments-it-helped-me-with">
                                    Ailments it helped me with
                                </div>
                                <div className="ailment-tag-wrapper">
                                    {renderAilmentTags}
                                </div>

                            </AilmentsSection>
                            : ""}

                        {item && item.pictures.length ?
                            <PicturesSection>
                                <PhotoGrid>
                                    {renderPictures}
                                </PhotoGrid>

                            </PicturesSection>
                            : ""}
                        <HelpfulText>
                            {loadingHelpfulReviews ? "Looking to see if anyone found this helpful..." : isOwner == false && helpfulReviews.length === 0 ? `Be the first person to find this review helpful` : helpfulReviews.length === 1 ? <div ><span className={classes.numberOfPeople}>1</span>  person found this helpful </div> : <div> <span className={classes.numberOfPeople}>{helpfulReviews.length} </span>people found this helpful</div>}
                        </HelpfulText>
                        <Actions>
                            {isOwner ? <>
                                <Button onClick={() => setRemoveDialogOpened(true)} disableElevation size="small" variant="contained">
                                    remove
                                </Button>


                            </> :
                                loadingHelpfulReviews ? "" :
                                    <>
                                        {!currentUserFoundThisHelpful && !hasBeenReported ?
                                            <>
                                                <Button className={classes.buttonTypeOne} onClick={() => isReviewHelpfulHandler()} disableElevation size="small" variant="contained">
                                                    {submittingReviewAsHelpful ? "Saving" : "This was Helpful for me"}
                                                </Button>
                                                < Divider className={classes.verticalDivider} orientation="vertical" flexItem />

                                            </>
                                            : currentUserFoundThisHelpful ? <div className={classes.alreadyFoundHelpful}>
                                                <span className={classes.alreadyFoundHelpfulText}>

                                                    You found this review helpful
                                                </span>

                                                < Divider className={classes.verticalDividerSmaller} orientation="vertical" flexItem />
                                                <Button className={classes.undoHelpfulButton} size={"small"} onClick={() => handleDeleteHelpfulHerbReview()}>{deletingReviewAsHelpful ? "Removing" : "Undo"} </Button>
                                            </div> : ""}


                                        {hasBeenReported ? <div className={classes.alreadyFoundHelpful}>
                                            <span className={classes.alreadyFoundHelpfulText}>
                                                You reported this review as abuse on {moment(usersAbusedReview.created_at).format("MMMM DD, YYYY")}
                                            </span>


                                            < Divider className={classes.verticalDividerSmaller} orientation="vertical" flexItem />
                                            <Button className={classes.undoHelpfulButton} size={"small"} onClick={() => handleDeletingAbusedReview()}>{deletingAbusedHerbReview ? 'Removing' : 'Undo'}</Button>

                                        </div> : !currentUserFoundThisHelpful ?
                                                <Button onClick={() => reportReviewHandler()} className={classes.buttonTypeTwo} disableElevation size="small" >
                                                    Report Abuse
                                        </Button> : ""}
                                    </>}
                        </Actions>

                    </Container>
                    : ""
            }

        </>
    )
}


HerbReviewItem.defaultProps = {
    loadHerbReviews: () => { },
    item: {
        created_at: "",
        description: "",
        pictures: [],
        rating: 0,
        remedyType: "",
        title: "",
        used_for_ailments: [],
        user: "",
        _id: ""
    }
}
HerbReviewItem.propTypes = {
    loadHerbReviews: PropTypes.func.isRequired,
    item: PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        description: PropTypes.string,
        pictures: PropTypes.array,
        rating: PropTypes.number,
        remedyType: PropTypes.string,
        title: PropTypes.string,
        used_for_ailments: PropTypes.array,
        user: PropTypes.string,
        _id: PropTypes.string
    })
}



export default HerbReviewItem