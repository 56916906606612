import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { getRemediesByAilmentID } from "../../services/remedy.services"
import { makeStyles } from "@material-ui/core/styles"
import SymptomItem from "./SymptomItem"
import {
  PageHeaderTypeOne,
  PageParagraphTypeOne,
  PageHeaderTypeTwo,
  PageH2TypeOne,
  UnorderedListTypeOne,
  DividerLineOne,
  BreakpointTablet,
  ShowingLimitOutOfCount,
} from "../Layout/GlobalStyle"
import DisclaimerItem from "../common/DisclaimerItem"
import RemedyItemTypeTwo from "../common/RemedyItemTypeTwo"
import ReferencePopper from "../common/ReferencePopper"
import UseTopicItem from "../UseTopicItem"
import LoadingSpinner2 from "../common/LoadingContainer/LoadingSpinner2"
import { Button } from "@material-ui/core"
import ProductsSectionGrid from "../ProductsSection/ProductsSectionGrid"
import ShareBar, {
  ShareBarColumn,
  ShareBarColumnLabel,
} from "../common/ShareBar"
import HerbsByAilmentSection from "../HerbsByAilmentSection"
import Advertisment from "../Advertisement"
import SignUpMessageBarWithCount from "../common/SignUpMessageBarWithCount"
import AuthUserContext from "../../providers/AuthProvider"

export const useAilmentStyles = makeStyles(theme => ({
  loadMoreRemediesButton: {
    fontFamily: "var(--Font1NotCondensed)",
    fontWeight: "bold",
    marginTop: 20,
    textTransform: "initial",
  },
}))

const Container = styled.div`
  display: grid;
  grid-gap: 140px;
  grid-template-columns: 1fr 0.7fr;
  padding-top: var(--PaddingPageTopOne);
  padding-bottom: 100px;
  @media only screen and (max-width: ${BreakpointTablet - 50 + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

const ColumnOne = styled.div`
  .information-section {
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media only screen and (max-width: ${BreakpointTablet - 50 + "px"}) {
      padding-top: 20px;
    }
  }
  padding-bottom: 80px;
`

export const RemedyColumnTwo = styled.div`
  .remedy-section {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

export const RemedyGridList = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`

export const RemedyGridListWrapper = styled.div`
  width: 100%;
  max-width: 600px;
`

export const AilmentSectionItem = styled.section`
  display: flex;
  flex-direction: column;
`

const defaultRemediesLimit = 5

function Ailment(props) {
  const {
    user,
    openSignInModal,
    isTheUserLoggedIn,
    openSignUpToBecomeAMember,
    remainingVisitsToWebsite,
    triggerVisitToWebsiteWithOutMembership,
  } = useContext(AuthUserContext)

  const classes = useAilmentStyles()
  const [ailment, setAilment] = useState({
    mongodb_id: "",
    name: "",
    slug: "",
    didYouKnow: "",
    fact: "",
    howCommon: "",
    description: "",
    disclaimer: "",
    cautionText: "",
    diagnosedBy: "",
    symptoms: [],
    questionsToAskYourDr: [],
  })

  // START OF REMEDIES USE STATE
  const [remedies, setRemedies] = useState([])
  const [remediesCount, setRemediesCount] = useState(0)
  const [remediesLimit, setRemediesLimit] = useState(defaultRemediesLimit)
  const [loadingRemedies, setLoadingRemedies] = useState(false)
  const [fetchingMoreRemedies, setFetchingMoreRemedies] = useState(false)
  // END OF REMEDIES USE STATE

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadData()
    }
    return () => (isSubscribed = false)
  }, [])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && ailment.mongodb_id) {
      loadRemedies()
    }
    return () => (isSubscribed = false)
  }, [ailment.mongodb_id])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      fetchMoreRemedies()
    }
    return () => (isSubscribed = false)
  }, [defaultRemediesLimit !== remediesLimit])

  const loadData = () => {
    let { data } = props
    let { mongodbHerbsAilments } = data
    let { internal, mongodb_id } = mongodbHerbsAilments
    let item = JSON.parse(internal.content)
    item.mongodb_id = mongodb_id
    setAilment(item)
    triggerVisitToWebsiteWithOutMembership(item.slug)
  }

  const loadRemedies = () => {
    setLoadingRemedies(true)
    getRemediesByAilmentID(ailment.mongodb_id, `?limit=${remediesLimit}`)
      .then(res => {
        if (res.status === 200) {
          setLoadingRemedies(false)
          setRemedies(res.data.remedies)
          setRemediesCount(res.data.count)
        }
      })
      .catch(err => {
        setLoadingRemedies(false)

        throw err
      })
  }
  const fetchMoreRemedies = () => {
    setFetchingMoreRemedies(true)
    getRemediesByAilmentID(ailment.mongodb_id, `?limit=${remediesLimit}`)
      .then(res => {
        if (res.status === 200) {
          setFetchingMoreRemedies(false)
          setRemedies(res.data.remedies)
          setRemediesCount(res.data.count)
        }
      })
      .catch(err => {
        setFetchingMoreRemedies(false)

        throw err
      })
  }

  let renderSymptoms = ailment.symptoms.map((id, index) => {
    if (id) {
      return <SymptomItem key={index} id={id} />
    }
  })

  const renderRemedies = remedies.map((item, index) => (
    <RemedyItemTypeTwo
      showAilments={false}
      itemIndex={index}
      key={index}
      item={item}
    />
  ))

  return (
    <>
      <SignUpMessageBarWithCount
        visible={user.id ? false : true}
        countLeft={remainingVisitsToWebsite}
        callbackIfZero={() => openSignUpToBecomeAMember()}
      />

      <Container>
        <ColumnOne>
          <PageHeaderTypeOne>{ailment.name}</PageHeaderTypeOne>
          <PageParagraphTypeOne>{ailment.description}</PageParagraphTypeOne>
          <ShareBarColumn>
            <ShareBarColumnLabel>Sharing is caring</ShareBarColumnLabel>

            <ShareBar
              hashtags={[
                `${ailment.name.replace(" ", "").trim()}`,
                `findanherb`,
                "naturalremedies",
                "madeinthe801",
                "herbfacts",
                "foodismedicine",
              ]}
              media={ailment.ogImage1200x628}
              title={ailment.name}
            />
          </ShareBarColumn>

          <section className="information-section">
            {ailment.symptoms && ailment.symptoms.length ? (
              <AilmentSectionItem>
                <PageH2TypeOne>Symptoms</PageH2TypeOne>
                <DividerLineOne />
                <UnorderedListTypeOne>{renderSymptoms}</UnorderedListTypeOne>
              </AilmentSectionItem>
            ) : null}
            {ailment.whatToExpect && ailment.whatToExpect.trim().length ? (
              <AilmentSectionItem>
                <PageH2TypeOne>What To Expect</PageH2TypeOne>
                <DividerLineOne />

                <PageParagraphTypeOne marginTop={"0px"}>
                  {ailment.whatToExpect}
                </PageParagraphTypeOne>
              </AilmentSectionItem>
            ) : null}

            {ailment.madeWorseBy && ailment.madeWorseBy.trim().length ? (
              <AilmentSectionItem>
                <PageH2TypeOne>Made Worse By</PageH2TypeOne>
                <DividerLineOne />

                <PageParagraphTypeOne marginTop={"0px"}>
                  {ailment.madeWorseBy}
                </PageParagraphTypeOne>
              </AilmentSectionItem>
            ) : null}
            {ailment.questionsToAskYourDr &&
            ailment.questionsToAskYourDr.length ? (
              <AilmentSectionItem>
                <PageH2TypeOne>Questions to ask your Doctor</PageH2TypeOne>
                <DividerLineOne />

                <PageParagraphTypeOne marginTop={"0px"}>
                  {ailment.questionsToAskYourDr.map((text, index) => {
                    return <UseTopicItem key={index} text={text} />
                  })}
                </PageParagraphTypeOne>
              </AilmentSectionItem>
            ) : null}
            {ailment.howCommon && ailment.howCommon.trim().length ? (
              <AilmentSectionItem>
                <PageH2TypeOne>How Common</PageH2TypeOne>
                <DividerLineOne />

                <PageParagraphTypeOne marginTop={"0px"}>
                  {ailment.howCommon}
                </PageParagraphTypeOne>
              </AilmentSectionItem>
            ) : null}

            {ailment.diagnosedBy && ailment.diagnosedBy.trim().length ? (
              <AilmentSectionItem>
                <PageH2TypeOne>Diagnosed By</PageH2TypeOne>
                <DividerLineOne />

                <PageParagraphTypeOne marginTop={"0px"}>
                  {ailment.diagnosedBy}
                </PageParagraphTypeOne>
              </AilmentSectionItem>
            ) : null}

            <div className="disclaimer-list">
              {ailment.cautionText && ailment.cautionText.trim().length ? (
                <DisclaimerItem title={"Caution"} text={ailment.cautionText} />
              ) : null}
            </div>
            <ProductsSectionGrid forAilment={true} ailment={ailment} />
            <HerbsByAilmentSection ailment={ailment} />
          </section>
        </ColumnOne>

        <RemedyColumnTwo>
          <PageHeaderTypeTwo>
            {loadingRemedies
              ? `Looking for remedies `
              : !loadingRemedies && remedies.length
              ? `Remedies that may help with ${ailment.name}`
              : !loadingRemedies && !remedies
              ? `No remedies found for ${ailment.name}`
              : ""}
          </PageHeaderTypeTwo>
          {!loadingRemedies && remediesCount ? (
            <ShowingLimitOutOfCount>
              Showing{" "}
              {remediesLimit < remediesCount ? remediesLimit : remediesCount}{" "}
              out of {remediesCount}{" "}
            </ShowingLimitOutOfCount>
          ) : null}

          <section className="remedy-section">
            {loadingRemedies ? (
              <LoadingSpinner2 color={"#05AA97"} />
            ) : (
              renderRemedies
            )}
          </section>
          {!loadingRemedies && remediesCount > remediesLimit ? (
            <Button
              disabled={fetchingMoreRemedies}
              disableElevation
              color={"secondary"}
              variant={"contained"}
              className={classes.loadMoreRemediesButton}
              onClick={() => setRemediesLimit(prevLimit => prevLimit + 2)}
            >
              {fetchingMoreRemedies
                ? "Loading more remedies"
                : "See more remedies"}{" "}
            </Button>
          ) : null}
          <br />
          <Advertisment maxWidth={"100%"} />
        </RemedyColumnTwo>

        {ailment.references && ailment.references.length ? (
          <ReferencePopper references={ailment.references} />
        ) : null}
      </Container>
    </>
  )
}

export default Ailment
