import axios from "./axios";
import { getHeaders } from "../components/Layout";




export const getRemedies = (query) => {
    return axios.get(`/api/remedies${query ? query : ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getRemediesByHerbID = (id, query) => {
    return axios.get(`/api/remedies/herb/${id}${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getTopRemedies = (query) => {
    return axios.get(`/api/remedies/top${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })

}

export const getRemedyVotesByRemedyID = (id) => {
    return axios.get(`/api/remedies/votes/${id}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const createRemedyVote = (body) => {
    return axios.post(`/api/remedies/vote`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getRemedyVotesByRemedyIDAndUserID = (remedyid, userid) => {
    return axios.get(`/api/remedies/user-votes/${remedyid}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getRemediesByAilmentID = (id, query) => {
    return axios.get(`/api/remedies/ailment/${id}${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getRemedyBySlug = (slug) => {
    return axios.get(`/api/remedies/slug/${slug}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

