import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import PropTypes, { string } from 'prop-types';

const Container = styled.div`
        margin-right: 5px;
        margin-top: 5px;


        a, .no-link{
            height: 21px;
            font-size: small;
            background: ${props => props.exists? '#A6DCD5' : '#c7c7c74a'};
            border-radius: 6px;
            border: 0px solid transparent;
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;
            justify-content: space-between;
            transition: 0.2s ease-in-out;

        }


        a{
            :hover{
                background: #405351;
                .slug-item-name{
                    text-decoration: underline;
                    color: white;
                }
            }
            .slug-item-name{
                text-decoration: underline;
            }
        }

        .no-link{
            user-select: none;
        }

    

        .slug-item-name{
            padding-left: 8px;
            padding-right: 8px;
            font-size: 12px;
            font-family: var(--Font2);
            color: ${props => props.exists? '#536877;' : 'initial;'};
            opacity: ${props => props.exists? 1 : 0.8};

           
        }

        :first-of-type{
            margin-left: 0px;
        }   

        .slug-item-image{
            position: relative;
            display: block;
            width: 21px;
            height: 21px;
            background-repeat: none;
            background-size: cover;
            ${props => props.src ? `background-image: url(${props.src})` : 'display: none;'}
        }
`

function HerbSlugItem(props) {
    let { herb } = props;

    const hasImage = herb && herb.pictures && herb.pictures[0] && herb.pictures[0].src ? true : false;

    return (
        <Container exists={herb.safeToPublish} src={hasImage ? herb.pictures[0].src : ''} >
            {herb.safeToPublish ?
                <Link target={"_blank"} to={`/herb/${herb.slug}`}>
                    <div className="slug-item-image">

                    </div>
                    <div className="slug-item-name">
                        {herb.name}
                    </div>
                </Link> :
                <div className="no-link">
                    <div className="slug-item-image">

                    </div>
                    <div className="slug-item-name">
                        {herb.name}
                    </div>
                </div>
            }
        </Container>
    )
}

HerbSlugItem.defaultProps = {
    herb: {
        slug: "",
        name: "",
        pictures: [],
        safeToPublish: false
    }
  
}

HerbSlugItem.propTypes = {
    herb: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        pictures: PropTypes.array,
        safeToPublish: PropTypes.bool
    }),
}


export default HerbSlugItem