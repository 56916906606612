import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import HerbSlugItem from "./HerbSlugItem"
import DisclaimerItem from "../DisclaimerItem"
import {
  getRemedyVotesByRemedyID,
  getRemedyVotesByRemedyIDAndUserID,
  createRemedyVote,
} from "../../../services/remedy.services"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ErrorsContext from "../../../providers/ErrorsProvider"
import AuthUserContext from "../../../providers/AuthProvider"
import { LOGIN, AILMENT } from "../../Layout/routes"
import { navigate } from "@reach/router"
import { BreakpointMobile } from "../../Layout/GlobalStyle"
import PropTypes from "prop-types"
import RemedyStepItem from "./RemedyStepItem"
import { Link } from "gatsby"
import IconButton from "@material-ui/core/IconButton"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import AilmentSlugItem from "./AilmentSlugItem"
export const VoteBar = styled.div`
  min-width: 40px;
  background: #f3fbf9;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    min-width: 40px;
  }
`

export const VoteUpDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const VoteDown = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0px solid transparent;
  color: #929595;
  cursor: pointer;
  color: ${props => (props.color ? props.color : "#929595")};
`
const VoteUp = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0px solid transparent;
  cursor: pointer;
  color: ${props => (props.color ? props.color : "#929595")};
`

const VoteCount = styled.div`
  font-size: large;
  font-family: var(--Font1NotCondensed);
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  color: ${props => (props.color ? props.color : "#929595")};
`

const RemedyTitle = styled.h2`
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: var(--Font1);
  font-weight: bold;
  text-transform: ${props =>
    props.textTransform ? `${props.textTransform};` : "uppercase;"};
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 19.2px;
  line-height: 25px;
  color: var(--TextTitleColor1);
`

export const RemedyHeader = styled.header`
  display: flex;
  justify-content: space-between;

  padding-bottom: 10px;

`

const RemedyOrderedList = styled.ol`
  font-family: var(--Font2);
  font-size: small;
  padding-left: 10px;
  margin-top: 0px;

  list-style: none;
  counter-reset: my-awesome-counter;

  li {
    counter-increment: my-awesome-counter;
  }
  li::before {
    content: counter(my-awesome-counter) ". ";
    color: var(--Color2);
    font-family: var(--Font1);
    font-weight: bold;
  }
`

export const RemedyItemStyled = styled.div`
  display: flex;
  border: 1px solid rgba(83, 104, 119, 0.5);
  border-radius: 4px;
  overflow: hidden;

  .ailment-link {
    color: var(--TextTitleColor1);
    text-decoration: underline;
    :hover {
      color: black;
    }
  }

  .remedy-instructions{
    padding-bottom: 15px;
  }

  .remedy-info {
    display: flex;
    flex-direction: column;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 13px;
    padding-top: 13px;

    @media only screen and (max-width: ${BreakpointMobile + "px"}) {
      min-width: 30px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .remedy-instruction-label {
    font-size: 17px;
    font-weight: bold;
    font-family: var(--Font1);
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 5px;
    color: var(--TextTitleColor1);
  }

  .remedy-instruction-text {
    font-size: small;
    margin-top: 0px;
    color: #554c57;
    font-family: var(--Font2);
    font-size: var(--ParagraphFontSize);
    line-height: var(--ParagraphLineHeight);
  }

  .remedy-description-text {
    font-size: small;
    margin-top: 0px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    color: #554c57;
    font-family: var(--Font2);
    font-size: var(--ParagraphFontSize);
    line-height: var(--ParagraphLineHeight);
  }

  .remedy-detail-title {
    font-size: medium;
    font-weight: bold;
    font-family: var(--Font1);
    line-height: 20px;
    padding-top: 10px;
  }
  .remedy-title {
    font-family: var(--Font1);
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 25px;
  }

  .herbs-used {
    display: flex;
    flex-wrap: wrap;
  }

  .herb-used-item {
    margin-left: 5px;
    margin-top: 5px;
    font-size: small;
    padding: 0px 5px 0px 5px;
    white-space: none;

    :first-of-type {
      margin-left: 0px;
    }
  }
`

const RemedyItemTypeTwo = ({ item, itemIndex, title, showAilments }) => {
  let { errors, setErrors, setErrorAlertOpened } = useContext(ErrorsContext)

  const [loadingRemedyVotes, setLoadingRemedyVotes] = useState(false)
  const [remedyPositiveVoteCount, setRemedyPositiveVoteCount] = useState(0)
  const [remedyNegativeVoteCount, setRemedyNegativeVoteCount] = useState(0)
  const [userHasVoted, setUserHasVoted] = useState(false)
  const [submittingVote, setSubmittingVote] = useState(false)
  const [usersVote, setUsersVote] = useState({
    remedy: "",
    user: "",
    voteDirection: 0,
  })
  let { user: currentUser, isTheUserLoggedIn, openSignInModal } = useContext(
    AuthUserContext
  )


  // START OF USE EFFECTS
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadVotes()
    }
    return () => {
      isSubscribed = false
    }
  }, [item._id, isTheUserLoggedIn === true])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && isTheUserLoggedIn) {
      loadVoteByUser()
    }
    return () => {
      isSubscribed = false
    }
  }, [loadingRemedyVotes !== true && isTheUserLoggedIn])

  // END OF USE EFFECTS

  // START OF API CALLS
  const loadVotes = () => {
    setLoadingRemedyVotes(true)

    getRemedyVotesByRemedyID(item._id)
      .then(res => {
        if (res.status == 200) {
          // setRemedyVotes(res.data)
          let { negativeTotal, positiveTotal } = res.data
          setRemedyPositiveVoteCount(positiveTotal)
          setRemedyNegativeVoteCount(negativeTotal)
        }
        setLoadingRemedyVotes(false)
      })
      .catch(err => {
        setLoadingRemedyVotes(false)
        setErrors(err.response.data)
        setErrorAlertOpened(true)
        throw err
      })
  }

  const loadVoteByUser = () => {
    getRemedyVotesByRemedyIDAndUserID(item._id)
      .then(res => {
        if (res.status === 200 && res.data) {
          setUserHasVoted(true)
          setUsersVote(res.data)
        } else {
          setUserHasVoted(false)
        }
      })
      .catch(err => {
        setErrors(err.response.data)
        setErrorAlertOpened(true)

        throw err
      })
  }

  // END OF API CALLS

  // START OF RENDER ITEMS
  let renderHerbs = item.herbs_used
    .sort((a, b) => b.safeToPublish - a.safeToPublish)
    .map((herb, index) => {
      return <HerbSlugItem herb={herb} key={index} />
    })

    let renderAilments = item.used_for_ailments
    .sort((a, b) => b.safeToPublish - a.safeToPublish)
    .map((ailment, index) => {
      return <AilmentSlugItem ailment={ailment} key={index} />
    })

  // END OF RENDER ITEMS

  const submitVote = amount => {
    createVote(amount)
  }

  const createVote = amount => {
    setSubmittingVote(true)
    createRemedyVote({ remedy: item._id, voteDirection: amount })
      .then(res => {
        if (res.status >= 200 && res.data) {
          if (res.data) {
            setUsersVote(res.data)
          }
          setSubmittingVote(false)
        }
        loadVotes()
      })
      .catch(err => {
        setSubmittingVote(false)
        loadVotes()
        setErrorAlertOpened(true)

        setErrors(err.response.data)
        throw err
      })
  }

  const handleVote = amount => {
    if (isTheUserLoggedIn) {
      submitVote(amount)
    } else {
      openSignInModal("Vote")
    }
  }

  const renderSteps = item.steps.map((step, index) => {
    return <RemedyStepItem step={step} key={index} />
  })

  const usedForAilmentsLength = item.used_for_ailments.length

  const renderTitle = showAilments ? (
    <RemedyTitle textTransform="initial">
      {item.used_for_ailments.map((ailment, index) => {
        const lastItem = index + 1 === usedForAilmentsLength ? true : false
        const beforeTheLastItemIndex = usedForAilmentsLength - 1
        const isBeforeLastItem = index + 1 === beforeTheLastItemIndex ? true : false

        const handleAndOrComma = lastItem ? "" : isBeforeLastItem ? "and" : ","
        let publishedAilment =
          ailment.safeToPublish && !ailment.draft ? true : false

        if (publishedAilment) {
          return (
            <div key={index} >
              <Link to={`${AILMENT}/${ailment.slug}`}>
                <span className={"ailment-link"}> {ailment.name} </span>
              </Link>
              {handleAndOrComma}
            </div>
          )
        } else {
          return (
            <div key={index}>
              <span > {ailment.name} </span>
              {handleAndOrComma}
            </div>
          )
        }
      })}
    </RemedyTitle>
  ) : (
    <RemedyTitle>
      {title} {itemIndex + 1}
    </RemedyTitle>
  )

  return (
    <RemedyItemStyled>
      <VoteBar>
        <VoteUpDownWrapper>
          <VoteUp
            disabled={submittingVote || loadingRemedyVotes}
            color={
              usersVote && usersVote.voteDirection === 1 ? "#eb6848" : "#929595"
            }
            onClick={() => handleVote(1)}
          >
            <ArrowDropUpIcon fontSize={"large"} />
          </VoteUp>
          <VoteCount
            color={
              usersVote && usersVote.voteDirection === 1
                ? "#eb6848"
                : usersVote && usersVote.voteDirection === -1
                ? "#12AA97"
                : "#929595"
            }
          >
            {remedyNegativeVoteCount + remedyPositiveVoteCount}
          </VoteCount>
          <VoteDown
            disabled={submittingVote || loadingRemedyVotes}
            color={
              usersVote && usersVote.voteDirection === -1
                ? "#12AA97"
                : "#929595"
            }
            onClick={() => handleVote(-1)}
          >
            <ArrowDropDownIcon fontSize={"large"} />
          </VoteDown>
        </VoteUpDownWrapper>
      </VoteBar>

      <div className="remedy-info">
        <RemedyHeader>
            <RemedyTitle>

        {item.name}
        </RemedyTitle>

        </RemedyHeader>
        {item.description !== "Remedy Description"?
        <p className="remedy-description-text">{item.description}</p>

          : null}
        <div className="remedy-instructions">
          <div className="remedy-detail-title">Ailments this remedy may help with:</div>
          <div className="herbs-used">{renderAilments}</div>
        </div>
        <div className="remedy-instructions">
          <div className="remedy-detail-title">Herbs used:</div>
          <div className="herbs-used">{renderHerbs}</div>
        </div>

       

        {item.steps.length > 0 ? (
          <>
            <div className="remedy-instructions instructions-section">
              <div className="remedy-instruction-label">Instructions:</div>
              <RemedyOrderedList>{renderSteps}</RemedyOrderedList>
            </div>
          </>
        ) : (
          <>
            <div className="remedy-instructions instructions-section">
              <div className="remedy-instruction-label">Instructions:</div>

              <p className="remedy-instruction-text">{item.instructions}</p>
            </div>
          </>
        )}

        <div className="remedy-caution-disclaimer-list">
          {item.cautionText ? (
            <DisclaimerItem title={"Caution"} text={item.cautionText} />
          ) : (
            ""
          )}
          {item.disclaimer ? (
            <DisclaimerItem title={"Disclaimer"} text={item.disclaimer} />
          ) : (
            ""
          )}
        </div>
      </div>
    </RemedyItemStyled>
  )
}

RemedyItemTypeTwo.defaultProps = {
  item: {
    name: "",
    instructions: "",
    _id: "",
    steps: [],
    used_for_ailments: [],
  },
  showAilments: false,
  title: "Remedy",
  itemIndex: 0,
}

RemedyItemTypeTwo.propTypes = {
  item: PropTypes.shape({
    steps: PropTypes.array,
    instructions: PropTypes.string,
    _id: PropTypes.string.isRequired,
    used_for_ailments: PropTypes.array,
  }),
  showAilments: PropTypes.bool.isRequired,
  title: PropTypes.string,
  itemIndex: PropTypes.number,
}

export default RemedyItemTypeTwo
