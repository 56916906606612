import React, { useState, useEffect, useContext, useRef } from "react"

import styled from "styled-components"
import {
  BreakpointTablet,
  SectionLabelOne,
  BreakpointMobile,
  ShowingLimitOutOfCount,
} from "../Layout/GlobalStyle"
import ProductsSection from "../ProductsSection"
import RelatedHerbs from "../RelatedHerbs"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import {
  updateHerbLastTimeViewed,
  getHerbFavoriteCount,
  getHerbRatingByHerbID,
} from "../../services/herb.services"
import { getRemediesByHerbID } from "../../services/remedy.services"
import {
  getFavoriteHerbByHerbID,
  createFavoriteHerb,
  deleteFavoriteHerb,
} from "../../services/favorite.services"
import UseTopicItem from "../UseTopicItem"
import UseTopicSimpleItem from "../UseTopicSimpleItem"
import LoadingContainer from "../common/LoadingContainer"
import LoadingSpinner2 from "../common/LoadingContainer/LoadingSpinner2"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import ReferencePopper from "../common/ReferencePopper"
import AuthUserContext from "../../providers/AuthProvider"
import Button from "@material-ui/core/Button"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import FavoriteIcon from "@material-ui/icons/Favorite"
import { makeStyles } from "@material-ui/core/styles"
import ShareBar from "../common/ShareBar"
import { FAVORITES } from "../Layout/routes"
import Loadable from "react-loadable"
import HerbReviews from "../HerbReviews"
import withLocation from "../common/withLocation"

import RemedyItemTypeTwo from "../common/RemedyItemTypeTwo"
import { RemedyGridListWrapper, RemedyGridList } from "../Ailment"
import HerbRating from "../HerbRating"
import CountDownBanner from "../CountDownBanner"
import Advertisment from "../Advertisement/Advertisment"
import ReadMore from "../common/ReadMore"
import SignUpMessageBarWithCount from "../common/SignUpMessageBarWithCount"
import BlogArticlesByHerb from "../BlogArticlesByHerb"

const Viewer = Loadable({
  loader: () => import("react-viewer"),
  loading: () => <div></div>,
})

const useStyles = makeStyles(theme => ({
  button: {
    fontFamily: "Roboto",
    textTransform: "initial",
    fontWeight: "bold",
  },
  activeHeart: {
    color: "#e91e63",
  },
  loadMoreRemediesButton: {
    fontFamily: "var(--Font1NotCondensed)",
    fontWeight: "bold",
    marginTop: 20,
    textTransform: "initial",
  },
}))

const Container = styled.div`
  margin: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  /* border: 1px solid blue; */
  .not-clickable {
    &:hover {
      text-decoration: none !important;
    }
  }

  .ailment-link {
    text-decoration: underline;
  }

  /* .herb-description-and-cta{
        .herb-button-outbound-link{
            width: 100%;
        }
    } */
`

const HerbParagraphText = styled.p`
  font-family: var(--Font2);
  margin-top: 0px;
  color: #4d4d4d;
  font-size: var(--ParagraphFontSize);
  line-height: var(--ParagraphLineHeight);
  ${props => (props.width ? `width: ${props.width}px;` : "")};

  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ""};
`

export const HerbInfoSectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${props =>
    props.paddingTop ? `${props.paddingTop}px` : "25px;"};
  padding-bottom: ${props =>
    props.paddingTop ? `${props.paddingTop}px` : "initial;"};
`

export const HerbHeadingSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 100px;
  padding-top: 54px;
  padding-bottom: 44px;

  @media only screen and (max-width: ${BreakpointTablet + 300 + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export const HerbInformationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 100px;
  /* padding-top: 54px;
    padding-bottom: 44px; */

  @media only screen and (max-width: ${BreakpointTablet + 300 + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    grid-template-columns: 1fr;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export const HerbInformationColumnOne = styled.div``
export const HerbInformationColumnTwo = styled.div`
  position: relative;
  width: 100%;
  max-width: 95vw;
`

export const HerbCallToActionColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;

  .favorite-count-details {
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 11px;
    font-family: var(--Font1NotCondensed);
    text-align: center;
    .herb-favorite-amount {
      font-weight: bold;
      color: var(--Color1);
      font-size: 13px;
    }
  }

  .favorite-herb-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .your-favorites-message {
    font-size: 14px;
    text-align: center;
    text-decoration: underline;

    a {
      width: inherit;
      font-weight: bold;
      color: var(--Color1);
      font-family: var(--Font1NotCondensed);
      text-align: center;

      :hover {
        color: var(--Color1Darker);
      }
    }
  }
  .cta-column {
    padding: 14px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    border: 1px solid #cccccc;
  }

  .cta-divider {
    width: 100%;
    background: #ccc;
    height: 1px;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .share-bar-column {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }

  .share-bar-label {
    padding-top: 0px;
    padding-bottom: 11px;
    font-family: "Roboto";
    font-size: 13px;
    color: #222220;
    font-weight: bold;
  }

  @media (max-width: ${BreakpointTablet + 300 + "px"}) {
    margin-top: 50px;
  }
`

export const HerbHeadingSection = styled.section`
  width: 100%;

  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
  .herb-name {
    font-family: var(--Font1);
    font-size: 28px;
    color: #222220;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
  }

  .herb-latin-name {
    font-family: var(--Font2);
    font-size: 14px;
    color: #4d4d4d;
    margin-top: 0px;
    line-height: normal;
    margin-bottom: 0px;
  }

  .herb-info-names {
    padding-bottom: 17px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }

  .herb-description-and-cta {
    display: flex;
    flex-direction: column;
  }

  .herb-description {
    font-family: var(--Font2);
    line-height: 22px;
    font-size: 16px;
    color: #4d4d4d;
  }

  @media (max-width: ${BreakpointTablet + "px"}) {
    border-bottom: 1px solid transparent;
    padding-bottom: 23px;

    display: flex;
    flex-direction: column-reverse;

    .herb-name {
      font-size: 24px;
    }

    .herb-latin-name {
      font-size: 13px;
    }

    .herb-info-names {
      padding-bottom: 10px;
    }

    .herb-description {
      font-size: 13px;
    }

    .herb-description-and-cta {
      padding-bottom: 23px;
    }

    .herb-info-names {
    }
  }
`

const HerbListsColumns = styled.div``

const ImageTiles = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 5px;
  position: relative;

  .main-image {
    height: 100%;
    display: flex;
    position: relative;
    width: 100%;
  }

  .desktop-img {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .other-images {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${BreakpointTablet + "px"}) {
    display: flex;
    flex-direction: column-reverse;

    .other-images {
      flex-direction: row;
    }
  }
`

const CallToActionButton = styled.button`
  background: var(--Color2);
  height: 40px;
  width: 180px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  text-transform: uppercase;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 20px;
  font-family: var(--Font1);
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
  border: 0px solid transparent;
  ${props => (props.color ? `background: ${props.color}` : "")}

  :hover {
    background: #d96043;
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    width: 100%;
  }
  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    width: 100%;
  }
`

const SmallImageItem = styled.img`
  margin-top: 5px;
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  ${props => props.src && `background-image: url(${props.src})`};
  border: 2px solid transparent;
  border-radius: 4px;
  ${props =>
    props.selected
      ? "border: 3px solid var(--Color2);"
      : "    cursor: pointer;"}
  object-fit: cover;

  :first-child {
    margin-top: 0px;
  }

  transition: 0.2s ease-in-out;

  @media (max-width: ${BreakpointTablet + "px"}) {
    margin-left: 5px;
    :first-child {
      margin-left: 0px;
      margin-top: 5px;
    }
  }
`

const ListOfUsesSection = styled.section`
  padding-bottom: 44px;
  padding-top: 44px;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;

  @media (max-width: ${BreakpointTablet + 300 + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

const ListOfUsesItem = styled.div`
  .use-topic-item {
    max-width: 500px;
  }

  .use-topic {
    display: flex;
    align-items: center;
  }
  .use-title {
    font-family: var(--Font1);
    font-weight: bold;
    padding-bottom: 12px;
    font-size: 24px;
    line-height: 25px;
    padding-bottom: 15px;
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .use-topic-text {
    font-size: var(--ParagraphFontSize);
    line-height: var(--ParagraphLineHeight);
    padding-left: 8px;
    font-weight: bold;
    display: flex;
  }

  .topic-item {
    font-family: var(--Font2);
  }

  .topic-description {
    color: #4d4d4d;
    font-size: var(--ParagraphFontSize);
    line-height: var(--ParagraphLineHeight);
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 15px;
    font-family: var(--Font2);
  }

  .bullet-and-topic {
    position: relative;
    padding-left: 10px;
    color: #222220;

    svg {
      position: absolute;
      left: 0;
      top: 40%;
    }
  }

  :first-child {
    padding-top: 0px;
  }
`

const NoPictureFound = styled.div`
  background: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  color: white;
  font-family: var(--Font2);
`

const ReferenceSection = styled.section`
  .reference-label {
    color: var(--LinkColor);
    font-family: var(--Font1);
    font-size: medium;
  }
`

const MainImage = styled.div`
  display: block;
  width: 100%;
  height: 220px;
  position: relative;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  img {
    border-radius: 4px;
    object-fit: cover;
    height: inherit;
    width: inherit;
  }

  .author-contribution {
    font-size: 10px;
    text-align: center;
    font-family: var(--Font2);
    line-height: 10px;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    a {
      color: inherit;
      :hover {
        text-decoration: underline;
        user-select: none;
      }
    }

    .not-clickable {
      text-decoration: none;
      user-select: none;
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: ${BreakpointTablet + "px"}) {
    height: 200px;
  }
`

const defaultRemediesLimit = 3

function Herb(props) {
  // START OF REFS
  const herbReviewsRef = useRef()

  // END OF REFS
  const classes = useStyles()
  const [imageViewerVisible, setImageViewerVisible] = useState(false)
  let { user, openSignInModal, isTheUserLoggedIn, remainingVisitsToWebsite, triggerVisitToWebsiteWithOutMembership, openSignUpToBecomeAMember } = useContext(AuthUserContext)
  const [ready, setReady] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [favoriteHerb, setFavoriteHerb] = useState(null)
  const [loadingFavoriteHerb, setLoadingFavoriteHerb] = useState(false)
  const [herbFavoriteCount, setHerbFavoriteCount] = useState(0)
  const [herb, setHerb] = useState({
    _id: "",
    mongodb_id: "",
    name: "",
    latinName: "",
    pictures: [{ src: "" }],
    partsUsed: [],
    keyActions: [],
    herbReviewCount: 0,
    used: [],
    ogImage1200x628: "",
    constituents: [],
    herbReviewAverage: 0,
    description: "",
    references: "",
    productLink: "",
    herbReviewCount: 0,
    herbReviewAverage: 0,
    slug: "",
  })

  const [herbRatingInfo, setHerbRatingInfo] = useState({
    _id: "",
    herbReviewAverage: 0,
    herbReviewCount: 0,
  })
  const [loadingHerbRating, setLoadingHerbRating] = useState(false)

  // START OF REMEDIES USE STATE
  const [remedies, setRemedies] = useState([])
  const [remediesCount, setRemediesCount] = useState(0)
  const [remediesLimit, setRemediesLimit] = useState(defaultRemediesLimit)
  const [loadingRemedies, setLoadingRemedies] = useState(false)
  const [fetchingMoreRemedies, setFetchingMoreRemedies] = useState(false)
  // END OF REMEDIES USE STATE

  // START OF USE EFFECTS
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadData(isSubscribed)
    }
    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && user.id) {
      loadFavoriteHerb()
      loadFavoriteCount()
    }
    return () => {
      isSubscribed = false
    }
  }, [user.id !== ""])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && herb.mongodb_id) {
      loadHerbRating()
    }
    return () => {
      isSubscribed = false
    }
  }, [herb.mongodb_id !== ""])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && herb.mongodb_id) {
      loadRemedies()
    }
    return () => {
      isSubscribed = false
    }
  }, [herb.mongodb_id])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && props.search && props.search.section && ready) {
      handleScrollToSection(props.search.section)
    }
    return () => {
      isSubscribed = false
    }
  }, [props.search && props.search.section && ready])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      fetchMoreRemedies()
    }
    return () => (isSubscribed = false)
  }, [defaultRemediesLimit !== remediesLimit])

  // END OF USE EFFECTS

  let {
    pictures,
    name,
    partsUsed,
    latinName,
    description,
    productLink,
    uses,
    keyActions,
    constituents,
  } = herb

  let { herbReviewCount, herbReviewAverage } = herbRatingInfo

  // START OF OTHER FUNCTIONS
  const handleScrollToSection = sectionName => {
    switch (sectionName) {
      case "herbReviewSection":
        return (
          herbReviewsRef.current &&
          herbReviewsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        )
      default:
        return ""
    }
  }
  // END OF OTHER FUNCTIONS

  //START OF API CALLS

  const loadFavoriteCount = () => {
    getHerbFavoriteCount(herb.mongodb_id)
      .then(res => {
        if (res.status === 200 && res.data) {
          setHerbFavoriteCount(res.data.count)
        }
      })
      .catch(err => {
        throw err
      })
  }

  const loadHerbRating = () => {
    setLoadingHerbRating(true)
    getHerbRatingByHerbID(herb.mongodb_id)
      .then(res => {
        if (res.status === 200) {
          setLoadingHerbRating(false)
          setHerbRatingInfo(res.data)
        }
      })
      .catch(err => {
        setLoadingHerbRating(false)

        throw err
      })
  }

  const markHerbAsFavorite = () => {
    if (user.id !== "") {
      setLoadingFavoriteHerb(true)

      createFavoriteHerb({ herbid: herb.mongodb_id })
        .then(res => {
          setFavoriteHerb(res.data)
          setLoadingFavoriteHerb(false)
          loadFavoriteCount()
        })
        .catch(err => {
          setLoadingFavoriteHerb(false)

          throw err
        })
    } else {
      openSignInModal("Favorite Herbs")
    }
  }

  const removeHerbAsFavorite = () => {
    if (user.id !== "") {
      setLoadingFavoriteHerb(true)
      deleteFavoriteHerb(herb.mongodb_id)
        .then(res => {
          if (res.status === 202) {
            setLoadingFavoriteHerb(false)

            setFavoriteHerb(null)
          }
          loadFavoriteCount()
        })
        .catch(err => {
          setLoadingFavoriteHerb(false)

          throw err
        })
    }
  }

  const loadData = (isSubscribed) => {
    let { data } = props

    setHerb(data)
    setReady(true)

    updateHerbLastTimeViewed(data.slug)
      .then(res => {
        if (res.status === 200 && isSubscribed) {
          triggerVisitToWebsiteWithOutMembership(data.slug)
          console.log("updated herb last time viewed")
        }
      })
      .catch(err => {
        throw err
      })
  }

  const loadFavoriteHerb = () => {
    setLoadingFavoriteHerb(true)
    getFavoriteHerbByHerbID(herb.mongodb_id)
      .then(res => {
        if (res.status === 200 && res.data) {
          setFavoriteHerb(res.data)
        }
        setLoadingFavoriteHerb(false)
        loadFavoriteCount()
      })
      .catch(err => {
        setLoadingFavoriteHerb(false)
        throw err
      })
  }

  const loadRemedies = () => {
    setLoadingRemedies(true)
    getRemediesByHerbID(herb.mongodb_id, `?limit=${remediesLimit}`)
      .then(res => {
        if (res.status === 200) {
          if (res.data.remedies) {
            setRemedies(res.data.remedies)
            setLoadingRemedies(false)
            setRemediesCount(res.data.count)
          }
        }
      })
      .catch(err => {
        setLoadingRemedies(false)

        throw err
      })
  }

  const fetchMoreRemedies = () => {
    setFetchingMoreRemedies(true)
    getRemediesByHerbID(herb.mongodb_id, `?limit=${remediesLimit}`)
      .then(res => {
        if (res.status === 200) {
          setFetchingMoreRemedies(false)
          setRemedies(res.data.remedies)
          setRemediesCount(res.data.count)
        }
      })
      .catch(err => {
        setFetchingMoreRemedies(false)

        throw err
      })
  }

  //END OF API CALLS

  // START OF RENDER ITEMS
  let renderOtherImages = pictures
    ? pictures.map((image, index) => {
        return (
          <SmallImageItem
            onClick={() => setCurrentImage(index)}
            selected={index === currentImage}
            src={image.src}
            alt={image.alt_text}
            key={index}
          />
        )
      })
    : null

  let renderKeyActions =
    herb && herb.keyActions
      ? keyActions.map((text, index) => {
          return <UseTopicSimpleItem text={text} key={index} />
        })
      : null

  let renderConstituents =
    herb && herb.constituents
      ? constituents.map((text, index) => {
          return <UseTopicSimpleItem text={text} key={index} />
        })
      : null

  let renderPartsUsed =
    herb && herb.partsUsed
      ? herb.partsUsed.map(({ type, description }, index) => {
          return (
            <UseTopicItem key={index} description={description} type={type} />
          )
        })
      : null

  let renderUses =
    herb && herb.uses
      ? herb.uses.map(({ type, description }, index) => {
          return (
            <UseTopicItem key={index} description={description} type={type} />
          )
        })
      : null

  let renderRemedies = remedies
    ? remedies.map((remedy, index) => {
        return (
          <RemedyItemTypeTwo
            showAilments={true}
            item={remedy}
            itemIndex={index}
          />
        )
      })
    : null

  const hasSeoImage =
    herb && herb.pictures && herb.pictures[0] && herb.pictures[0].src
      ? true
      : false
  let pictureURL = pictures[currentImage].url

  let renderFavoriteCount =
    herbFavoriteCount === 0 ? (
      <>Be the first person to like this herb</>
    ) : herbFavoriteCount === 1 ? (
      <>
        <span className={"herb-favorite-amount"}>{herbFavoriteCount} </span>
        person likes this herb
      </>
    ) : (
      <>
        <span className={"herb-favorite-amount"}>{herbFavoriteCount} </span>
        people like this herb
      </>
    )

  let picturesForViewer = herb.pictures
    ? herb.pictures.map(item => {
        item.alt = item.caption
        return item
      })
    : []
  // END OF RENDER ITEMS

  return (
    <>
      {!ready ? (
        <LoadingContainer />
      ) : (
        <>
          {typeof window !== "undefined" && herb.pictures ? (
            <Viewer
              visible={imageViewerVisible}
              onClose={() => {
                setImageViewerVisible(false)
              }}
              activeIndex={currentImage}
              onMaskClick={() => setImageViewerVisible(false)}
              images={picturesForViewer}
              drag={false}
              disableMouseZoom={true}
              scalable={false}
              loop={true}
              zIndex={10000}
              zoomable={false}
              rotatable={false}
              defaultScale={1}
            />
          ) : (
            null
          )}
          <Container>
            <SignUpMessageBarWithCount callbackIfZero={() => openSignUpToBecomeAMember()} visible={user.id? false: true} countLeft={remainingVisitsToWebsite} />
            <HerbHeadingSectionWrapper>
              <HerbHeadingSection>
                {hasSeoImage ? (
                  <>
                    <ImageTiles selectedImage={pictures[currentImage]}>
                      <div className="other-images">{renderOtherImages}</div>
                      <div className="main-image-wrapper">
                        <div className="main-image">
                          <MainImage
                            onClick={() => setImageViewerVisible(true)}
                          >
                            <img
                              src={pictures[currentImage].src}
                              alt={pictures[currentImage].alt_text || name}
                            />
                            {pictures[currentImage].taken_by ? (
                              <div className="author-contribution">
                                {pictures[currentImage].url ? (
                                  <OutboundLink
                                    target={"_blank"}
                                    href={pictures[currentImage].url}
                                  >
                                    Picture taken by{" "}
                                    {pictures[currentImage].taken_by}
                                  </OutboundLink>
                                ) : (
                                  <div className="not-clickable">
                                    Picture taken by{" "}
                                    {pictures[currentImage].taken_by}
                                  </div>
                                )}
                              </div>
                            ) : (
                              null
                            )}
                          </MainImage>
                        </div>
                      </div>
                    </ImageTiles>
                  </>
                ) : (
                  <NoPictureFound>
                    <div>No Picture Found</div>
                  </NoPictureFound>
                )}
                <div className="herb-heading-description-information">
                  <div className="herb-info-names">
                    <h1 className="herb-name">{name}</h1>
                    <h2 className="herb-latin-name">{latinName}</h2>
                    <HerbRating
                      loadingHerbRating={loadingHerbRating}
                      herbReviewAverage={herbReviewAverage}
                      herbReviewCount={herbReviewCount}
                      herbID={herb.mongodb_id}
                      herbSlug={herb.slug}
                    />
                  </div>
                  <div className="herb-description-and-cta">
                    <ReadMore text={description}>
                      <HerbParagraphText>{description}</HerbParagraphText>
                    </ReadMore>
                  </div>
                </div>
              </HerbHeadingSection>
              <HerbCallToActionColumn>
                <div className="cta-column">
                  {productLink ? (
                    <>
                      <OutboundLink
                        className="herb-button-outbound-link"
                        hrefLang="en-us"
                        href={productLink.replace("http://", "https://")}
                        target="_blank"
                      >
                        <Button
                          className={classes.button}
                          startIcon={<ShoppingCartIcon />}
                          variant={"contained"}
                          color="secondary"
                          disableElevation
                          fullWidth
                        >
                          Buy From Amazon
                        </Button>
                      </OutboundLink>
                      <div className="cta-divider"></div>
                    </>
                  ) : (
                    null
                  )}

                  <div className="favorite-herb-column">
                    {favoriteHerb ? (
                      <Button
                        fullWidth
                        disabled={
                          loadingFavoriteHerb.toString() === "true"
                            ? true
                            : false
                        }
                        className={classes.button}
                        onClick={() => removeHerbAsFavorite()}
                        startIcon={
                          <FavoriteIcon className={classes.activeHeart} />
                        }
                      >
                        {loadingFavoriteHerb.toString() === "true"
                          ? "Loading"
                          : "Added to favorites"}
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        disabled={
                          loadingFavoriteHerb.toString() === "true"
                            ? true
                            : false
                        }
                        className={classes.button}
                        onClick={() => markHerbAsFavorite()}
                        startIcon={<FavoriteBorderIcon />}
                      >
                        {loadingFavoriteHerb.toString() === "true"
                          ? "Loading"
                          : "Add to favorites"}
                      </Button>
                    )}
                  </div>

                  <div className="cta-divider"></div>
                  <div className="your-favorites-message">
                    <Link to={FAVORITES}>Go to my favorites</Link>
                  </div>
                </div>
                <div className="favorite-count-details">
                  {renderFavoriteCount}
                </div>
                {hasSeoImage ? (
                  <div className="share-bar-column">
                    <label className="share-bar-label">Sharing is caring</label>
                    <ShareBar
                      hashtags={[
                        `${herb.name.replace(" ", "").trim()}`,
                        "findanherb",
                        "naturalremedies",
                        "madeinthe801",
                        "herbfacts",
                      ]}
                      media={herb.ogImage1200x628}
                      title={herb.name}
                    />
                  </div>
                ) : (
                  null
                )}
              </HerbCallToActionColumn>
            </HerbHeadingSectionWrapper>

            <HerbInformationWrapper>
              <HerbInformationColumnOne>
                {herb.historyAndFolklore &&
                herb.historyAndFolklore.trim().length ? (
                  <HerbInfoSectionColumn>
                    <SectionLabelOne>History & Folklore</SectionLabelOne>
                    <HerbParagraphText>
                      {herb.historyAndFolklore}
                    </HerbParagraphText>
                  </HerbInfoSectionColumn>
                ) : (
                  null
                )}
                {herb.habitatCultivation &&
                herb.habitatCultivation.trim().length ? (
                  <HerbInfoSectionColumn>
                    <SectionLabelOne>Habitat & Cultivation</SectionLabelOne>
                    <HerbParagraphText>
                      {herb.habitatCultivation}
                    </HerbParagraphText>
                  </HerbInfoSectionColumn>
                ) : (
                  null
                )}

                <ListOfUsesSection>
                  {herb && herb.uses && herb.uses.length ? (
                    <ListOfUsesItem>
                      <h4 className="use-title">Uses</h4>
                      <div className="topics-container">{renderUses}</div>
                    </ListOfUsesItem>
                  ) : (
                    null
                  )}

                  {herb && herb.partsUsed && herb.partsUsed.length ? (
                    <ListOfUsesItem>
                      <h4 className="use-title">Parts used</h4>
                      <div className="topics-container">{renderPartsUsed}</div>
                    </ListOfUsesItem>
                  ) : (
                    null
                  )}
                  {herb && herb.keyActions && herb.keyActions.length ? (
                    <ListOfUsesItem>
                      <h4 className="use-title">Key Actions</h4>
                      <div className="topics-container">{renderKeyActions}</div>
                    </ListOfUsesItem>
                  ) : (
                    null
                  )}

                  {herb && herb.constituents && herb.constituents.length ? (
                    <ListOfUsesItem>
                      <h4 className="use-title">Constituents</h4>
                      <div className="topics-container">
                        {renderConstituents}
                      </div>
                    </ListOfUsesItem>
                  ) : (
                    null
                  )}
                </ListOfUsesSection>
              </HerbInformationColumnOne>

              <HerbInformationColumnTwo>
                {isTheUserLoggedIn ? null : <CountDownBanner />}
                <Advertisment maxHeightDesktop={"480px"} maxWidth={"300px"} />

                <RelatedHerbs herb={herb} />
                <br />
                <BlogArticlesByHerb herb={herb} />
              </HerbInformationColumnTwo>
            </HerbInformationWrapper>

            <ReferencePopper references={herb.references} />

            <HerbInfoSectionColumn>
              <SectionLabelOne paddingBottom={loadingRemedies ? 15 : 1}>
                {loadingRemedies ? (
                  `Looking for remedies containing ${herb.name}`
                ) : remedies.length ? (
                  `Remedies & Self Help using ${herb.name}`
                ) : (
                  <div className="not-found-text">
                    No Remedies found containing {herb.name}
                  </div>
                )}
              </SectionLabelOne>
              {!loadingRemedies && remediesCount ? (
                <ShowingLimitOutOfCount>
                  Showing{" "}
                  {remediesLimit < remediesCount
                    ? remediesLimit
                    : remediesCount}{" "}
                  out of {remediesCount}{" "}
                </ShowingLimitOutOfCount>
              ) : (
                null
              )}

              {loadingRemedies ? (
                <LoadingSpinner2 color={"#05AA97"} />
              ) : (
                <>
                  <RemedyGridListWrapper>
                    <RemedyGridList>{renderRemedies}</RemedyGridList>
                    {!loadingRemedies && remediesCount > remediesLimit ? (
                      <Button
                        disabled={fetchingMoreRemedies}
                        disableElevation
                        color={"secondary"}
                        variant={"contained"}
                        className={classes.loadMoreRemediesButton}
                        onClick={() =>
                          setRemediesLimit(prevLimit => prevLimit + 2)
                        }
                      >
                        {fetchingMoreRemedies
                          ? "Loading more remedies"
                          : `See more remedies using ${herb.name}`}{" "}
                      </Button>
                    ) : (
                      null
                    )}
                  </RemedyGridListWrapper>
                </>
              )}
            </HerbInfoSectionColumn>
            <ProductsSection herb={herb} />
            <HerbReviews
              herbRatingInfo={herbRatingInfo}
              ref={herbReviewsRef}
              herb={herb}
            />
          </Container>
        </>
      )}
    </>
  )
}

export default withLocation(Herb)
