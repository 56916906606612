import axios from "./axios";



export const getProductsByHerbID = (id, query) => {
    return axios.get(`/api/products/herb/${id}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const getProductsByAilmentID = (id, query) => {
    return axios.get(`/api/products/ailment/${id}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getProductPictureByProductID = (productid) => {
    return axios.get(`/api/products/picture/${productid}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const createProduct = (body) => {
    return axios.post(`/api/products`, body)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


