import React from 'react'
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getHerbsByHerbFamilyID } from '../../services/herb.services';
import { SectionLabelOne } from '../Layout/GlobalStyle';
import HerbMediumListItem from '../HerbMediumListItem';
import styled from 'styled-components'
import {HerbInfoSectionColumn} from '../Herb'




const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`

 function RelatedHerbs(props) {
   const [loading, setLoading] = useState(false)
   const [herbs, setHerbs] = useState([])
   const {herb} = props;
   const {herbFamily: herbFamilyID, name, mongodb_id} = herb;
   const [limit, setLimit] = useState(5)

   useEffect(() => {
     let isSubscribed = true;
     if(isSubscribed && herbFamilyID){
       loadHerbFamilies()
     }

     return () => isSubscribed = false;

   },[herbFamilyID])


   const loadHerbFamilies = () => {
     setLoading(true)
     getHerbsByHerbFamilyID(herbFamilyID, `?limit=${limit}`)
        .then(res => {
          if(res.status === 200 && res.data){
            let filteredHerbs = res.data.herbs.filter(item => item._id !== mongodb_id)
            setHerbs(filteredHerbs)
          }
          setLoading(false)

        })
        .catch(err => {
          setLoading(false)

          throw err
        })
   }


   const renderItems = herbs.map((item, index) => {
     return(<HerbMediumListItem key={index} herb={item} />)
   })
  return (
    <HerbInfoSectionColumn >
      <SectionLabelOne>
        {loading? "Looking for related herbs": herbs.length?  `Relatives to ${name}` : "" }
      </SectionLabelOne>
      <List>
        {renderItems}
      </List>
    </HerbInfoSectionColumn>
  )
}



RelatedHerbs.propTypes = {
  herb: PropTypes.shape({
    herbFamily: PropTypes.string,
    name: PropTypes.string.isRequired,
    mongodb_id: PropTypes.string.isRequired
  })
}



export default RelatedHerbs
