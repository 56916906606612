import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { getUserPublicInformation } from "../../../services/user.services"

const Container = styled.div`
  display: flex;
  align-items: center;
  .users-name {
    padding-left: 9px;
    font-size: 14px;
    color: var(--GreyColorOne);
    font-family: var(--Font1);
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
`

const UserAvatar = styled.div`
  display: block;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  border-radius: 100%;
  background-size: cover;
  background-position: 50%;
  ${props =>
    props.src ? `background-image: url(${props.src});` : "background: grey;"}
`

function UserOfReview(props) {
  let [user, setUser] = useState({
    _id: "",
    first_name: "",
    last_name: "",
    avatar: "",
  })
  let { id } = props

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && props.id) {
      loadUser()
    }

    return () => (isSubscribed = false)
  }, [props.id])

  const loadUser = () => {
    getUserPublicInformation(props.id)
      .then(res => {
        if (res.status === 200 && res.data) {
          setUser(res.data)
        }
      })
      .catch(err => {
        throw err
      })
  }
  let { first_name, last_name } = user

  return (
    <Container>
      <UserAvatar src={user.avatar ? user.avatar : ""} />
      <h6 className="users-name">
        {first_name ? `${first_name} ${last_name[0]}.` : "Name of user"}
      </h6>
    </Container>
  )
}

UserOfReview.defaultProps = {
  id: "",
}

export default UserOfReview
