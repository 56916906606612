import React from 'react'
import { BulletPointIcon } from '../Layout/GlobalStyle'


 function UseTopicItem(props) {

    let {type, description} = props;
    return (
        <div className="use-topic-item">
        <span className="use-topic">
            <p className="topic-description">
                <span className="bullet-and-topic">
                    <BulletPointIcon />
                    <b>{type + " "}</b>
                </span>
                {description}
            </p>
        </span>
    </div>
    )
}

UseTopicItem.defaultProps ={
    type: "",
    description: ""
}


export default UseTopicItem