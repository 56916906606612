import axios from './axios'
import { getHeaders } from '../components/Layout'


export const getHerbReviewsByHerbID = (id, query) => {
    return axios.get(`/api/herb-reviews/herb/${id}${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}



export const getAbusedHerbReviewsByReviewID = (reviewid, query) => {
    return axios.get(`/api/herb-reviews/abused/${reviewid}${query ? query : ''}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const getSuggestedHerbsToReview = () => {
    return axios.get(`/api/herb-reviews/suggested-herbs-to-review`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}



export const getReviewByHerbAndUserID = (userid, herbid) => {
    return axios.get(`/api/herb-reviews/user/${userid}/herb/${herbid}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}




export const reportHerbReviewAsAbused = (body) => {
    return axios.post(`/api/herb-reviews/abused`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const submitHerbReview = (body) => {
    return axios.post(`/api/herb-reviews`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const markReviewAsHelpfulByHerbReviewID = (reviewid) => {
    return axios.get(`/api/herb-reviews/helpful/${reviewid}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getHerbHelpfulReviewsByReviewID = (id) => {
    return axios.get(`/api/herb-reviews/helpful-reviews/${id}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getReviewRatingTotalByStarValue = (ratingvalue, herbid) => {
  
    return axios.get(`/api/herb-reviews/rating-amount-total/${ratingvalue}/${herbid}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const deleteHerbReviewsByReviewID = (reviewid) => {
    return axios.delete(`/api/herb-reviews/${reviewid}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const deleteHelpfulHerbReview = (helpfulreviewid) => {
    return axios.delete(`/api/herb-reviews/helpful/${helpfulreviewid}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const deleteAbusedHerbReviewByID = (abusedHerbReviewID) => {
    return axios.delete(`/api/herb-reviews/abused/${abusedHerbReviewID}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}
