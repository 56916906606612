import React from 'react'
import Layout from '../components/Layout'
import PropTypes from "prop-types"
import Herb from '../components/Herb'
import { HERB } from '../components/Layout/routes'


const HerbTemplate = ({ pathContext: { herbData } }) => {
    let herbpath = `${HERB}/${herbData.slug}`
    let title = herbData.name
    let description = herbData.description ? herbData.description.substring(0, 150) : "This herb has no description";

    const seoProps = {
        title: `${title} and it's benefits `,
        description: description,
        type: "website",
        pathname: herbpath,
        image: {
            src: herbData.ogImage1200x628,
            width: 1200,
            height: 628
        }
    }
    return (
        <Layout seoProps={seoProps}>
            <Herb data={herbData} />
        </Layout>
    )
}


HerbTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}


export default HerbTemplate