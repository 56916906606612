import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getAilments } from '../../services/ailment.services';
import { Link } from 'gatsby'
import { AILMENT } from '../Layout/routes';
const Container = styled.span`
    background-color: var(--Color1Lightest);
    color: var(--TextTagColor1);
    font-family: var(--Font2);
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    min-height: 21px;
    border-radius: 6px;
    margin-left: 5px;
    :first-of-type {
    margin-left: 0px;
}
    
`


const StyledLink = styled(Link)`
    background-color: var(--Color1Lightest);
    color: var(--TextTagColor1);
    font-family: var(--Font2);
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    min-height: 21px;
    border-radius: 6px;
    margin-left: 5px;
    text-decoration: underline;
    transition: 0.2s ease-in-out;

    :first-of-type {
    margin-left: 0px;
}

:hover{
                background: #405351;
                    text-decoration: underline;
                    color: white;

                
            }
    
`


function AilmentTag(props) {
    let [ailment, setAilment] = useState({
        name: "",
        slug: "",
        draft: true,
        safeToPublish: false,
        _id: ""
    })
    let [loading, setLoading] = useState(false)


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            loadAilment()
        }
        return () => {
            isSubscribed = false;
        }
    }, [props.id])


    const loadAilment = () => {
        setLoading(true)
        getAilments(`?id=${props.id}&&minimumData=true`)
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setAilment(res.data)
                }
            })
            .catch(err => {
                throw err
            })
    }


    let { id } = props;



    return (
        <>
            {loading ? <> </> :
                ailment.draft === false && ailment.safeToPublish === true ?
                    <StyledLink to={`${AILMENT}/${ailment.slug}`}>
                        {ailment.name}

                    </StyledLink> :

                    <Container>
                        {ailment.name}

                    </Container>
            }
        </>
    )
}

AilmentTag.defaultProps = {
    id: ""
}


export default AilmentTag