import React from 'react'
import styled from 'styled-components'
import LogoNodejs from 'react-ionicons/lib/IosWarning.js'
const Container = styled.div`
    display: block;
position: relative;
    

    .disclaimer-wrapper{
       position: relative;
        p{
            display: inline-block;
            width: inherit;
            background: rgba(247, 161, 32, 0.22);
            border: 1px solid rgba(247, 161, 23, 0.62);
            padding: 5px;
            
            border-radius: 4px;
            font-size: 11px;
        }
    }

`

function DisclaimerItem(props) {
    let { text, title } = props;
    return (
        <Container>
            <span className="disclaimer-wrapper">
                <p>
                   

                      <b className="b-wrap">               
{title} </b> 

                    {text}
                </p>
            </span>

        </Container>
    )
}


DisclaimerItem.defaultProps = {
    text: "",
    title: "Disclaimer:"
}


export default DisclaimerItem