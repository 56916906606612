import { Button } from '@material-ui/core'
import styled from 'styled-components'
import { BreakpointMobile, BreakpointTablet } from '../../Layout/GlobalStyle'



export const ProductItemWrapper = styled.div`
    display: block;
    position: relative;
    overflow: hidden;

    .out-bound-link{
        position: inherit;
    }
`




export const BuyProductButton = styled(Button)`
    font-family: "Roboto";
    text-transform: initial;
    font-weight: bold;
    font-size: 8pt;
    padding-left: 5px;
    padding-right: 5px;
    width: 130px;
    margin: auto;

`

export const ProductCarouselItem = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    .product-image{
        border-radius: 4px;
        width: 100%;
        height: 130px;
        background-size: contain;
        position: relative;
        background-position: 50%;
        background-repeat: no-repeat;
        img{
            object-fit: contain;
            width: 100%;
        height: 130px;
        border-radius: 4px;
        ${props => props.src ? `content: url(${props.src});` : `content: url(${props => props.theme.defaultProductListIcon});`};
            transition: 0.2s ease-in-out;
        }

    }

    .herb-button-outbound-link{
        display: flex;
        justify-content: center;
    }

    .product-title{
        font-size: 13px;
        color: #222220;
        font-family: var(--Font1);
        line-height: 20px;
        text-align: center;
        :hover{
            text-decoration: underline;
        }
    }

    .product-information{
        height: 70px;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: ${BreakpointTablet + 'px'}) {
        .product-image{
            height: 102px;   
        }
        padding: 6px;
    }

    :hover{
        .product-title{
            color: var(--Color2);
        }
        
    }

`
