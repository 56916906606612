import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { BreakpointTablet, SectionLabelOne } from '../Layout/GlobalStyle';
import { getProductsByHerbID } from '../../services/product.services';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import LoadingSpinner2 from '../common/LoadingContainer/LoadingSpinner2';
import Loadable from 'react-loadable';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ProductItem from './ProductItem'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import OwlCarousel from 'react-owl-carousel'
const OwlCarousel = Loadable({
    loader: () => import('react-owl-carousel'),
    loading: () => <div></div>,
});

const Container = styled.div`
    /* border-bottom: 1px solid #ccc; */
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;

    .owl-prev, .owl-next {
        width: 15px;
        height: 100px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block !important;
        border:0px solid black;
    }
    .owl-prev { left: -20px; }
    .owl-next { right: -20px; }
    .owl-prev i, .owl-next i {transform : scale(2,5); color: #ccc;}

    /* .products-section-title{
        font-family: var(--Font1);
        text-transform: uppercase;
        font-weight: bold;
        color: #222220;
        padding-bottom: 25px;
    } */

    .product-grid{
        display: grid;
        grid-template-columns: repeat(auto-fill, 130px);
        grid-gap: 25px;
        width: 100%;
    }

    @media screen and (max-width: ${BreakpointTablet + 'px'}) {
        /* padding-top: 0px; */
        .products-section-title{
            font-size: 16px;
            padding-bottom: 15px;
        }

        /* .product-grid{
            grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
        } */
    }

`




const LoadMore = styled.button`
    height: 34px;
    width: 100%;
    margin-top: 14px;
    font-size: 16px;
    font-family: var(--Font1);
    background: var(--Color2);
    color: white;
    text-transform: uppercase;
    border: 0px solid transparent;
`

const ProductsSection = (props) => {
    let { herb } = props;
    let { name } = herb;
    let [products, setProducts] = useState([])
    let [count, setCount] = useState(0)
    let [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(14)


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            loadData()
        }
        return () => isSubscribed = false;
    }, [props.herb._id, props.herb.mongodb_id])

    const loadData = () => {
        setLoading(true)
        if (props.herb.mongodb_id) {
            getProductsByHerbID(props.herb.mongodb_id, `?limit=${limit}`)
                .then(res => {
                    if (res.status === 200) {
                        setProducts(res.data.products)
                        setCount(res.data.count)
                        setLoading(false)

                    }
                })
                .catch(err => {
                    throw err
                })
        } else if (props.herb._id) {
            getProductsByHerbID(props.herb._id, `?limit=${limit}`)
                .then(res => {
                    if (res.status === 200) {
                        setProducts(res.data.products)
                        setCount(res.data.count)
                        setLoading(false)

                    }
                })
                .catch(err => {
                    throw err
                })
        }


    }





    let renderProducts = products.map((p, i) => {
        return ( <ProductItem item={p} key={i} />)
    })



    return (
        <Container>
            <SectionLabelOne paddingBottom={20}>

                {loading && herb.name !== "" ? <span className="loading-place-holder-text">
                    Looking for products with {herb.name}
                </span> : loading && !herb.name ? `Looking for products` : !loading && products.length === 0 ? <span className="not-found-text">No Products Found For {name}</span> : "Products with " + name}

            </SectionLabelOne>
            {loading ? <LoadingSpinner2 color={"#05AA97"} /> :

                <>
                    {typeof window !== "undefined" ?
                        <OwlCarousel
                            dots={true}
                            margin={10}



                            items={7}
                            className="owl-theme"
                            responsive={{
                                0: {
                                    items: 2
                                },
                                500: {
                                    items: 3
                                },
                                700: {
                                    items: 4
                                },
                                1024: {
                                    items: 5
                                },
                                1220: {
                                    items: 6
                                },
                                1440: {
                                    items: 7
                                }
                            }

                            }

                        >
                            {renderProducts}

                        </OwlCarousel>

                        : ""}
                </>}
        </Container >
    )
}

ProductsSection.defaultProps = {
    herb: {
        mongodb_id: false,
        name: "Herb Name",
        _id: false
    }

}

export default ProductsSection