import styled from "styled-components"
import { BreakpointMobile } from "../../Layout/GlobalStyle"

export const Container = styled.div`


  display: ${({ visible = true }) => (visible ? "block" : "none")};
  min-height: 55px;
  margin-top: 50px;
  font-size: 14px;
  .message-span-with-count {
    width: 100%;
    max-width: 854px;
    border-radius: 4px;
    font-family: var(--Font1NotCondensed);
    color: white;
    padding: 19px;
    background: #444444;
    text-shadow: 0px 3px 6px rgb(0 0 9 / 16%);
    a {
      color: white;

      text-decoration: underline !important;
      cursor: pointer;
    }
    b {
      font-weight: bold;
      color: var(--Color2);
    }
  }



  @media only screen and (max-width: 900px) {
    .message-span-with-count {
      max-width: 100%;
      box-sizing: inherit;
      display: block;
    }
  }

  @media only screen and (max-width: ${BreakpointMobile + 'px'}) {
    margin-top: 20px;
    .message-span-with-count {
      max-width: 100%;
      box-sizing: inherit;
      display: block;
    }
  }

  
`
