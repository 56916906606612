import React, {useState, useEffect} from 'react'
import { getSymptoms } from '../../../services/symptom.services';
import styled from 'styled-components'
import {BulletPointIcon} from '../../Layout/GlobalStyle'

const Container = styled.div`
    ${props => props.display? 'display: block;': 'display: none;'}
    .bullet-and-text{
        display: flex;
        align-items: center;
        font-size: var(--ParagraphFontSize);
        line-height: var(--ParagraphLineHeight);   
             font-family: var(--Font2);
        font-weight: bold;
        color: var(--TextTitleColor1);
    }

    .bullet-span{
        display: flex;
        align-items: center;
        padding-right: 5px;
    }
    margin-bottom: 10px;
`

export default function SymptomItem(props) {
    let [symptom, setSymptom] = useState({
        _id: "",
        name: "",
        slug: ""
    })

    let [loading, setLoading] = useState(false)


    useEffect(() => {
        let isSubscribed = true;
        if(isSubscribed && props.id){
            loadData()
        }

        return () => isSubscribed = false;
    },[props.id])

    const loadData = () => {
        setLoading(true)
        getSymptoms(`?id=${props.id}`)
            .then(res => {
                if(res.status === 200 && res.data){
                    setSymptom(res.data)
                    setLoading(false)
                }
            })
            .catch(err => {
                setLoading(false)

                throw err
            })
    }



    return (
        <Container display={symptom.name !== ""? true: false}>
            <div className="bullet-and-text">
            <span className="bullet-span">
            <BulletPointIcon />

            </span>
                {symptom.name}
            </div>

        </Container>
    )
}
