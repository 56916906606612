import React, {useState} from 'react'
import { BulletPointIcon } from '../Layout/GlobalStyle'


function UseTopicSimpleItem(props) {

    let {text} = props;

    return (
        <div className="use-topic-item">
        <span className="use-topic">
            <p className="topic-description">
                <span className="bullet-and-topic">
                    <BulletPointIcon />
                </span>
                {text}

            </p>
        </span>
    </div>
    )
}

UseTopicSimpleItem.defaultProps ={
    text: ""
}


export default UseTopicSimpleItem