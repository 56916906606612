import {baseURL} from './baseURL'
const axios = require('axios')


export const getSymptoms = (query) => {
    return axios.get(`${baseURL}/api/symptoms${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}



