import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import getPercentage from '../../utils/getPercentage';
import PropTypes from 'prop-types'
import { getReviewRatingTotalByStarValue } from '../../services/herb-review.services';

export const RatingBarsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    width: 100%;
    max-width: 400px;
`



const Container = styled.div`
    display: grid;
    grid-template-columns: 50px 210px 50px;
    grid-gap: 10px;
    align-items: center;

`

const StarLabel = styled.div`
    font-size: 16px;
    font-family: var(--Font1);
    font-weight: bold;
    color: var(--Color1);

`

const PercentageAmountLabel = styled.div`
    font-size: 16px;
    font-family: var(--Font1);
    font-weight: bold;
    color: var(--Color1);
`

const PercentageBarWrapper = styled.div`
    display: block;
    height: 24px;
    background: #F3F3F3;
    position: relative;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    overflow: hidden;

`

const PercentageBarFill = styled.div`
    background: var(--Color1);
    position: relative;
    width: ${props => props.width ? `${props.width}%;` : '0%;'};
    height: inherit;
    border-width: 1px;
    border-top-color: var(--Color1Darker);
    border-left-color: var(--Color1Darker);
    border-bottom-color: var(--Color1Darker);
    border-right-color: transparent;

`

 function RatingBar({targetRatingValue, herbID}) {
     const [loadingInfo, setLoadingInfo] = useState(false)
     let [ratingInfo, setRatingInfo] = useState({
        totalWithRating: 0,
        totalForHerb: 0
     })

    useEffect(() => {
        let isSubscribed = true;
        if(isSubscribed && herbID && targetRatingValue){
            loadInformation()
        }

        return () => isSubscribed = false;

    },[targetRatingValue && herbID])

    const loadInformation = () => {
        setLoadingInfo(true)
        getReviewRatingTotalByStarValue(targetRatingValue, herbID)
            .then(res => {
                if(res.status === 200){
                    setRatingInfo(res.data)
                    setLoadingInfo(false)
                }
            })
            .catch(err => {
                setLoadingInfo(false)
                throw err
            })
    }

    const {totalForHerb, totalWithRating} = ratingInfo;

    const percentageValue = getPercentage(totalWithRating, totalForHerb)? getPercentage(totalWithRating, totalForHerb): 0;
    return (
        <Container>
            <StarLabel>
                {targetRatingValue} star
            </StarLabel>
            <PercentageBarWrapper>
                <PercentageBarFill width={percentageValue} />
            </PercentageBarWrapper>
            <PercentageAmountLabel>
                {percentageValue}%
            </PercentageAmountLabel>
            
        </Container>
    )
}

RatingBar.propTypes = {
    targetRatingValue: PropTypes.number.isRequired,
    herbID: PropTypes.string.isRequired
}

export default RatingBar
