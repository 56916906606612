import React, {useState, useEffect} from "react"
import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import RenderRating from "../common/Rating";
import PropTypes from 'prop-types'
import { HERB } from "../Layout/routes";
import { getHerbRatingByHerbID } from "../../services/herb.services";

const HerbRatingHeader = styled.div`
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    max-width: 300px;

    .rating-count-and-link{
        font-size: small;
    }
`

function HerbRating({herbID, herbSlug,herbReviewAverage, herbReviewCount, loadingHerbRating}) {

  return (
    <AnchorLink to={`${HERB}/${herbSlug}#herbReviewSection`}>
      <HerbRatingHeader>
        {!loadingHerbRating?
                  <>
 
        <RenderRating value={herbReviewAverage} />
        <div className="link-style-one">
          {herbReviewCount ? `${herbReviewCount} ${herbReviewCount === 0? 'reviews': herbReviewCount ===1? 'review': 'reviews'}` : `0 ratings`}
        </div>
        </>
: ""}
      </HerbRatingHeader>
    </AnchorLink>
  )
}

HerbRating.propTypes = {
    herbReviewCount: PropTypes.number.isRequired,
    herbReviewAverage: PropTypes.number.isRequired,
    herbSlug: PropTypes.string.isRequired,
    herbID: PropTypes.string.isRequired
}


export default HerbRating
