import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const OrderedListItemStyled = styled.li`
  font-size: var(--ParagraphFontSize);
  color: var(--GreyColorTwo);
  margin-bottom: 15px;
  line-height: 24px;



`

 const RemedyStepItem = ({step}) => (
  <OrderedListItemStyled>
      {step}
  </OrderedListItemStyled>
)


RemedyStepItem.defaultProps = {
  step: ""
}

RemedyStepItem.propTypes = {
  step: PropTypes.string.isRequired
}

export default RemedyStepItem
